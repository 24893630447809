// src/components/Layout/Layout.js
import React from 'react';
import Header from '../Header';

const Layout = ({ children, isScrolled }) => {
  return (
    <>
      <Header isScrolled={isScrolled} />
      <main>{children}</main>
    </>
  );
};

export default Layout;
