import React from "react";
import "./Next.css";
import PayImg from "../../assets/Image/payimg.webp";
import PosImg from "../../assets/Image/pos.webp";
import Pos2Img from "../../assets/Image/pos2.webp";
import Pos3Img from "../../assets/Image/pos3.webp";
const Next = () => {
  return (
    <div className="Next_container">
      <div className="Next_side_img">
        <img className="Next_img_com" src={PayImg} alt="payment image" />
        <div className="Next_full_side_text">
          <p className="Next_text_side">Text Content</p>
        </div>
      </div>
      <div className="Next_line"></div>

      <div className="Next_three_images">
        <div className="Next_img_div">
          <img className="Next_image_1" src={PosImg} alt="payment image" />
          <div className="Next_full_side_text">
            <p className="Next_text">POS</p>
            <p className="Next_sub_text">Learn more</p>
          </div>
        </div>

        <div className="Next_img_div">
          <img className="Next_image_1" src={Pos2Img} alt="payment image" />
          <div className="Next_full_side_text">
            <p className="Next_text">Sound Box</p>
            <p className="Next_sub_text">Learn more</p>
          </div>
        </div>
      </div>

      <div className="lite_orange_box_next">
        <div className="inventory_list_next">
          <div className="list_item">
            <p>POS</p>
            <p className="arrow_icon">→</p>
            <div className="hover_image invoice_image">
              <img
                className="normal_img_com"
                src={PayImg}
                alt="Invoice Management"
              />
              <div className="next_texts">
                <p className="posdevice_2">POS Device</p>
                <p className="Learn_more_2">Learn more</p>
              </div>
            </div>
          </div>
          <div className="list_item">
            <p>Sound Box</p>
            <p className="arrow_icon">→</p>
            <div className="hover_image multi_invoice_image">
              <img
                className="normal_img_com"
                src={PayImg}
                alt="Invoice Management"
              />
              <div className="next_texts">
                <p className="posdevice_2">POS Device</p>
                <p className="Learn_more_2">Learn more</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Next;
