import React, { useEffect } from 'react';
import sneakers from '../assets/Image/managementbook.png';
import travel from '../assets/Image/onlinestore .png';
import diamond from '../assets/Image/possoftware.png';
import events from '../assets/Image/posnew.png';
import beauty from '../assets/Image/loan.png';
import FURNITURE from '../assets/Image/FURNITURE.webp';
import './style/Features.css';

const Features = () => {

    useEffect(() => {
        const firstRowBoxOne = document.querySelector('.first-row .large-card');
        const firstRowBoxTwo = document.querySelector('.first-row .small-card:nth-child(2)');
        const firstRowBoxThree = document.querySelector('.first-row .small-card:nth-child(3)');

        const handleMouseOverFirstRowBoxTwo = () => {
            firstRowBoxOne.style.flex = '0 0 20%';
            firstRowBoxTwo.style.flex = '0 0 40%';
        };

        const handleMouseOutFirstRowBoxTwo = () => {
            firstRowBoxOne.style.flex = '0 0 40%';
            firstRowBoxTwo.style.flex = '0 0 20%';
        };

        const handleMouseOverFirstRowBoxThree = () => {
            firstRowBoxOne.style.flex = '0 0 20%';
            firstRowBoxThree.style.flex = '0 0 40%';
        };

        const handleMouseOutFirstRowBoxThree = () => {
            firstRowBoxOne.style.flex = '0 0 40%';
            firstRowBoxThree.style.flex = '0 0 20%';
        };

        if (firstRowBoxTwo) {
            firstRowBoxTwo.addEventListener('mouseover', handleMouseOverFirstRowBoxTwo);
            firstRowBoxTwo.addEventListener('mouseout', handleMouseOutFirstRowBoxTwo);
        }

        if (firstRowBoxThree) {
            firstRowBoxThree.addEventListener('mouseover', handleMouseOverFirstRowBoxThree);
            firstRowBoxThree.addEventListener('mouseout', handleMouseOutFirstRowBoxThree);
        }

        const secondRowBoxOne = document.querySelector('.second-row .small-card:nth-child(1)');
        const secondRowBoxTwo = document.querySelector('.second-row .small-card:nth-child(2)');
        const secondRowBoxThree = document.querySelector('.second-row .large-card');

        const handleMouseOverSecondRowBoxOne = () => {
            secondRowBoxThree.style.flex = '0 0 20%';
            secondRowBoxOne.style.flex = '0 0 40%';
        };
        const handleMouseOutSecondRowBoxOne = () => {
            secondRowBoxThree.style.flex = '0 0 40%';
            secondRowBoxOne.style.flex = '0 0 20%';
        };
        const handleMouseOverSecondRowBoxTwo = () => {
            secondRowBoxThree.style.flex = '0 0 20%';
            secondRowBoxTwo.style.flex = '0 0 40%';
        };
        const handleMouseOutSecondRowBoxTwo = () => {
            secondRowBoxThree.style.flex = '0 0 40%';
            secondRowBoxTwo.style.flex = '0 0 20%';
        };
        if (secondRowBoxOne) {
            secondRowBoxOne.addEventListener('mouseover', handleMouseOverSecondRowBoxOne);
            secondRowBoxOne.addEventListener('mouseout', handleMouseOutSecondRowBoxOne);
        }

        if (secondRowBoxTwo) {
            secondRowBoxTwo.addEventListener('mouseover', handleMouseOverSecondRowBoxTwo);
            secondRowBoxTwo.addEventListener('mouseout', handleMouseOutSecondRowBoxTwo);
        }

        return () => {
            if (firstRowBoxTwo) {
                firstRowBoxTwo.removeEventListener('mouseover', handleMouseOverFirstRowBoxTwo);
                firstRowBoxTwo.removeEventListener('mouseout', handleMouseOutFirstRowBoxTwo);
            }

            if (firstRowBoxThree) {
                firstRowBoxThree.removeEventListener('mouseover', handleMouseOverFirstRowBoxThree);
                firstRowBoxThree.removeEventListener('mouseout', handleMouseOutFirstRowBoxThree);
            }

            if (secondRowBoxOne) {
                secondRowBoxOne.removeEventListener('mouseover', handleMouseOverSecondRowBoxOne);
                secondRowBoxOne.removeEventListener('mouseout', handleMouseOutSecondRowBoxOne);
            }

            if (secondRowBoxTwo) {
                secondRowBoxTwo.removeEventListener('mouseover', handleMouseOverSecondRowBoxTwo);
                secondRowBoxTwo.removeEventListener('mouseout', handleMouseOutSecondRowBoxTwo);
            }
        };
    }, []);

    return (
        <div style={{marginBottom:'5%'}}> 
            <div className="container first-row">
                <div className="icon-card large-card" style={{ backgroundColor: '#EEDCD8' }}>
                    <div className="text-block">
                        <h3>NTAR PAY</h3>
                        <p>Bill Payments</p>
                        <button>Let’s NTAR</button>
                    </div>
                    <img src={FURNITURE} alt="Furniture" />
                </div>
                <div className="icon-card small-card" style={{ backgroundColor: 'rgba(225, 154, 118, 1)' }}>
                    <h3>NTAR BOOK</h3>
                    <p>Management</p>
                    <img src={sneakers} alt="Sneakers" />
                    <button style={{background:'rgba(225, 154, 118, 1)'}} className='blue-button'>Let’s NTAR</button>
                </div>
                <div className="icon-card small-card" style={{ backgroundColor: '#FEF9C4' }}>
                    <h3>NTAR Next</h3>
                    <p>Online Store </p>
                    <img src={travel} alt="Travel" />
                    <button style={{background:'#CFC30D'}}>Let’s NTAR</button>
                </div>
            </div>
            <div className="container second-row">
                <div className="icon-card small-card" style={{ backgroundColor: '#DDD99C' }}>
                    <h3>NTAR LINK</h3>
                    <p>Loan</p>
                    <img src={beauty} alt="Beauty" />
                    <button style={{background:'#E1D40B'}}>Let’s NTAR</button>
                </div>
                <div className="icon-card small-card" style={{ backgroundColor: '#A7CED1' }}>
                    <h3>NTAR POS</h3>
                    <p>POS Device</p>
                    <img src={events} alt="Events" />
                    <button style={{background:'#03CAD0'}}>Let’s NTAR</button>
                </div>
                <div className="icon-card large-card" style={{ backgroundColor: '#FAE8E8' }}>
                    <div className="text-block">
                        <h3>NTAR CHOICE</h3>
                        <p>POS Software</p>
                        <button style={{background:'rgba(255, 86, 20, 1)',marginTop:'50px'}}>Let's NTAR</button>
                    </div>
                    <img src={diamond} alt="Diamond Ring" />
              
                </div>
            </div>
        </div> 
    );
};

export default Features;
