import React,{useEffect} from "react";
import "./Cookiepolicy.css";
import Footer from "../../Components/Footer.jsx";
import Help from "../../Components/Help/Help.jsx";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const Cookiepolicy = () => {
  const location = useLocation();

  useEffect(() => {
    // Function to send page view information to Google Analytics
    const trackPageView = (url) => {
      window.gtag("config", "G-XNZ6JS7Y9J", {
        page_path: url,
      });
    };

    // Track page view when the location changes
    trackPageView(location.pathname);
  }, [location]);
  return (
    <div style={{ background: "white" }}>
      <Helmet>
        <link rel="canonical" href="https://ntarbizz.com/Cookiepolicy/" />
      </Helmet>
      <Helmet>
        <title>
          Cookies Policy | NTAR Bizz - Understanding How We Use Cookies
        </title>
        <meta
          name="description"
          content="Learn how NTAR Bizz uses cookies to enhance your experience and ensure secure transactions. Our Cookies Policy outlines the types of cookies we use and how we protect your privacy."
        />
        <meta
          name="keywords"
          content="NTAR bizz Cookies Policy, Cookies policy"
        />
      </Helmet>
      <section className="cookie-policy">
        <h1 className="cooky">Cookie Policy</h1>
      </section>

      <section className="section_2">
        <p className="intro_heading">Introduction</p>
      </section>

      <div className="Cookie_policy_data">
        <p>
          NTAR Bizz Private Limited ("we," "us," or "our") uses cookies and
          similar technologies on our merchant platform to enhance your
          experience, improve services, and offer relevant information. This
          Cookies Policy explains how we use these technologies, your choices
          regarding them, and how they affect you as a merchant.
        </p>
        <br />
        <p>
          <strong>1. What Are Cookies?</strong>
        </p>
        <p>
          Cookies are small data files stored on your device (computer, mobile,
          tablet) when you access our platform. These files help us remember
          information about your visit, making your experience more personalized
          and efficient.
        </p>
        <br />
        <p>
          <strong>2. Types of Cookies We Use</strong>
          <p>We use the following types of cookies:</p>
        </p>
        <ul>
          <li>
            <strong>Essential Cookies:</strong> These cookies are necessary for
            the operation of our platform, enabling core functionalities like
            security, network management, and accessibility.
          </li>
          <li>
            <strong>Performance Cookies: </strong> These cookies help us analyze
            how merchants use the platform, allowing us to measure and improve
            its performance and efficiency.
          </li>
          <li>
            <strong>Functional Cookies:</strong> These cookies enable enhanced
            functionality and personalization, such as remembering your
            preferences and settings.
          </li>
          <li>
            <strong>Targeting Cookies: </strong> These cookies collect
            information to deliver relevant advertising and marketing messages
            based on your activity.
          </li>
        </ul>

        <br />
        <p>
          <strong>3. How We Use Cookies</strong>
          <p>Cookies help us:</p>
        </p>
        <ul>
          <li>
            <strong>Enhance User Experience: </strong> By remembering your
            preferences, cookies allow for faster login and seamless navigation.
          </li>
          <li>
            <strong>Analyze Performance: </strong>We use cookies to gather
            insights into platform usage, helping us identify areas for
            improvement.
          </li>
          <li>
            <strong>Security and Compliance: </strong> Cookies are used to
            detect suspicious activities and enhance overall platform security.
          </li>
          <li>
            <strong>Marketing and Personalization: </strong> We tailor
            communications based on your interactions to provide relevant
            information and offers.
          </li>
        </ul>

        <p>
          <strong>4. Third-Party Cookies</strong>
        </p>
        <p>
          We may also allow third-party service providers, such as analytics and
          advertising partners, to use cookies on our platform. These cookies
          help them provide their services, such as targeted advertising and
          performance analytics. We do not control these third-party cookies and
          recommend reviewing their respective privacy policies.
        </p>
        <br></br>
        <p>
          <strong>5. Updates to This Policy</strong>
        </p>
        <p>
          We may update this Cookies Policy periodically to reflect changes in
          technology, legal requirements, or our business operations. We
          encourage you to review this policy regularly for any updates.
        </p>
        <br></br>
        <p>
          <strong>6. Contact Us</strong>
        </p>
        <p>
          If you have any questions or concerns about our use of cookies, please
          contact us at:
        </p>
        <br></br>
        <p>
          <strong>NTAR Bizz Private Limited</strong>
        </p>
        <strong>
          {" "}
          Email: <a href="bizz@ntarbizz.com ">hello@ntarbizz.com </a>.
        </strong>
      </div>

      {/* Help Center Section */}

      <Help
        buttonLabel="Chat Support"
        href="https://wa.me/9188545454"
        isExternalLink={true}
      />
      <br></br>
      <br />
      <Footer />
    </div>
  );
};

export default Cookiepolicy;
