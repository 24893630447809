import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import "./Privacypolicy.css";
import Help from "../../Components/Help/Help.jsx";
import Footer from "../../Components/Footer.jsx";
import book from "../../assets/Image/book.png";
import Education from "../../assets/Image/education.png";
import goldsavings from "../../assets/Image/goldsav.png";
import Eligibility from "../../assets/Image/Eligibility.png";
import Credentials from "../../assets/Image/credentialsconfi.png";
import userobligations from "../../assets/Image/userobligations.png";
import Fee from "../../assets/Image/fee.png";
import { Helmet } from "react-helmet";
import { GlobalContext } from "../../Components/GlobalContext/GlobalContext.jsx";
import { useNavigate } from "react-router-dom";

const helpItems = [
  {
    id: 1,
    title: "Privacy Policy",
    description:
      "Privacy Policy, outlining how we collect, use, and protect your information",
    image: book,
    link: "/Faqsection",
  },
  {
    id: 2,
    title: "Legal Compliance",
    description:
      "Protecting your business through adherence to laws and regulations.",
    image: Education,
    link: "/Faqsection",
  },
  {
    id: 3,
    title: "Information Collection",
    description:
      "Information we collect from users and how we utilize this data.",
    image: goldsavings,
    link: "/Faqsection",
  },
  {
    id: 4,
    title: "Merchant Information",
    description: "Policy outlines the requirements for businesses.",
    image: Eligibility,
    link: "/Faqsection",
  },
  {
    id: 5,
    title: "Cookies&Technologies",
    description:
      "We explain our use of cookies to improve your browsing experience.",
    image: Credentials,
    link: "/Faqsection",
  },
  {
    id: 6,
    title: "Third-Party",
    description:
      "We explain how third-party services may access your information and their role.",
    image: userobligations,
    link: "/Faqsection",
  },
  {
    id: 7,
    title: "Children's Information",
    description: "We do not knowingly collect information from children.",
    image: userobligations,
    link: "/Faqsection",
  },
  {
    id: 8,
    title: "Services Policy",
    description:
      "Our service policy provides clarity and consistency for your business.",
    image: Fee,
    link: "/Faqsection",
  },
  {
    id: 9,
    title: "Changes to This Privacy Policy",
    description:
      "We will notify you of any significant changes to our privacy policy.",
    image: Fee,
    link: "/Faqsection",
  },
];

const Privacypolicy = () => {
  const { activeHeader, setActiveHeader } = useContext(GlobalContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState(helpItems);
  const [noResultsFound, setNoResultsFound] = useState(false);

  useEffect(() => {
    const trackPageView = (url) => {
      window.gtag("config", "G-XNZ6JS7Y9J", {
        page_path: url,
      });
    };
    trackPageView(location.pathname);
  }, [location]);

  useEffect(() => {
    const trackPageView = (url) => {
      window.gtag("config", "G-XNZ6JS7Y9J", { page_path: url });
    };

    trackPageView(location.pathname);
  }, [location]);

  console.log("activeHeader====>,,activeHeader", activeHeader);
  const filteredhelpItems = helpItems.filter((topic) =>
    topic.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setNoResultsFound(filteredhelpItems.length === 0);
  }, [searchTerm, filteredhelpItems]);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchTerm(query);

    const filtered = helpItems.filter(
      (item) =>
        item.title.toLowerCase().includes(query) ||
        item.description.toLowerCase().includes(query)
    );
    setFilteredItems(filtered);
  };

  return (
    <div style={{ background: "white" }}>
      <Helmet>
        <link rel="canonical" href="https://ntarbizz.com/Privacypolicy/" />
      </Helmet>
      <Helmet>
        <title>Privacy Policy | NTAR Bizz</title>
        <meta
          name="description"
          content="Read the NTAR Bizz Privacy Policy to understand how we collect, use, and safeguard your business and personal information."
        />
        <meta
          name="keywords"
          content="NTAR Bizz, Privacy policy, Security Policies, NTAR Bizz policies, terms and policies, policy terms"
        />
      </Helmet>

      <section className="privacy-policy">
        <h1
          style={{
            color: "white",
            fontSize: "70px",
            fontFamily: "Youth",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          Privacy Policy
        </h1>
        <br />
        <div className="support-center">
          <p style={{ fontSize: "18px" }}>Support Center</p>
          <p
            className="browse-through"
            style={{ color: "white", fontSize: "14px", textAlign: "center" }}
          >
            Browse through our frequently asked questions, tutorials, and other
            self-help resources to
            <br />
            find the answers you need.
          </p>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search here (e.g. Privacy, Cookies)"
              value={searchTerm}
              onChange={handleSearch}
            />
            <button>Search</button>
          </div>
        </div>
      </section>

      <section className="help-center">
        <div className="small_cards">
          <h2>Browse our help centre</h2>
          <div className="help-cards">
            {filteredItems.length > 0 ? (
              filteredItems.map((item, index) => (
                <a
                  key={index}
                  className="help-card"
                  href={item.link}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveHeader(item.title); 
                    navigate(item.link);
                  }}
                >
                  <img
                    className="icon_size"
                    src={item.image} 
                    alt={item.title}
                  />
                  <h3 style={{ textDecoration: "none", color: "black" }}>
                    {item.title}
                  </h3>
                  <p>{item.description}</p>
                </a>
              ))
            ) : (
              <p style={{ textAlign: "left", fontSize: "18px", color: "red" }}>
                Sorry, no results found.
              </p>
            )}
          </div>
        </div>
      </section>

      <Help
        buttonLabel="Chat Support"
        href="https://wa.me/9188545454"
        isExternalLink={true}
      />
      <br />
      <Footer />
    </div>
  );
};

export default Privacypolicy;
