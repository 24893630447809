import React,{useEffect} from "react";
import "./GrievancePolicy.css";
import Footer from "../../Components/Footer.jsx";
import Help from "../../Components/Help/Help.jsx";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const GrievancePolicy = () => {
  const location = useLocation();

  useEffect(() => {
    // Function to send page view information to Google Analytics
    const trackPageView = (url) => {
      window.gtag("config", "G-XNZ6JS7Y9J", {
        page_path: url,
      });
    };

    // Track page view when the location changes
    trackPageView(location.pathname);
  }, [location]);
  return (
    <div style={{background:'white'}}>
      <Helmet>
        <link rel="canonical" href="https://ntarbizz.com/GrievancePolicy/" />
      </Helmet>
      <Helmet>
        <title>Grievance Policy | NTAR Bizz - Resolving Concerns with Care</title>
        <meta
          name="description"
          content="Learn about NTAR Bizz's Grievance Policy designed to address and resolve customer concerns. We ensure timely responses to queries regarding payment services, transactions, and more. Your satisfaction is our priority."
        />
        <meta
          name="keywords"
          content="NTAR bizz Grievance Policy, policy, agreement, security, legal"
        />
      </Helmet>
      <section className="Grievance-Policy">
        <h1 className="cooky">Grievance Policy</h1>
      </section>

      <section className="section_2">
   
      </section>

      <div className="Cookie_policy_data">
        <p>
          <strong>1. Introduction</strong>
        </p>
        <p>
          At NTAR Bizz Private Limited, we are committed to providing the best
          possible service to our merchants. We value feedback and are dedicated
          to resolving any grievances you may have in a fair, transparent, and
          prompt manner. This Grievance Policy outlines the process for lodging
          complaints and the steps we take to address your concerns.
        </p>
        <br />
        <p>
          <strong>2.Scope</strong>
        </p>
        <p>
          This policy applies to all merchants using the NTAR Bizz app, whether
          online (B2B ) or offline. It covers complaints related to
          transactions, services, customer support, and any other issues
          experienced while using our platform.
        </p>
        <br />
        <p>
          <strong>3.Grievance Redressal Mechanism</strong>
        </p>
        <ul>
          <li>
            <strong>Step 1: Raising a Complaint</strong>
            <br/>
            <p>
              Merchants can raise a grievance through the following channels:
            </p>
          </li>
     
          <li>
            <strong>In-App Support: </strong> Use the 'Help' or 'Support'
            section in the NTAR Bizz app to submit your complaint.
          </li>
   
          <li>
            <strong>Email: </strong> Send your complaints to our designated
            email address:<a href="hello@ntarbizz.com"> hello@ntarbizz.com</a>
          </li>
     
          <li>
            <strong>Phone: </strong> Call our merchant support 99850 99850
          </li>
      
          <li>
            <strong>Escalation: </strong>If the issue is not resolved through
            the above channels, you can escalate it by writing to our Grievance
            Officer.
          </li>
        </ul>
        <br />

        <p>
          <strong>4. Contact Details of the Grievance Officer</strong>
        </p>
        <ul>
          <li>
            <strong>Name:</strong> Kasthuri Madhu
          </li>
       
          <li>
            <strong>Email: </strong><a href="grievance.officer@ntarbizz.com">grievance.officer@ntarbizz.com</a>
          </li>
      
          <li>
            <strong>Phone:</strong> 99850 99850
          </li>
        </ul>
     <br/>
        <p>
          <strong>5. Merchant Responsibilities</strong>
        </p>
        <ul>
          <li>
            Merchants are expected to provide complete and accurate information
            when lodging grievances.
          </li>
          <li>Keep your unique reference number safe for tracking purposes.</li>
          <li>
            Engage cooperatively with our support team during the grievance
            redressal process.
          </li>
        </ul>
        <br></br>
        <p>
          <strong>6. Policy Review</strong>
        </p>
        <p>
          This Grievance Policy will be reviewed periodically to ensure its
          effectiveness and compliance with applicable laws and regulations.
          NTAR Bizz Private Limited reserves the right to modify this policy at
          its discretion, with updates communicated to merchants via the NTAR
          Bizz app and official communications.
        </p>
        <br></br>
        <p>
          <strong>7.Conclusion</strong>
        </p>
        <p>
          We strive to maintain a transparent and efficient grievance redressal
          mechanism to enhance your experience as a valued merchant partner. For
          any unresolved issues, feedback, or suggestions, please do not
          hesitate to reach out to us.
        </p>
      
      </div>

      {/* Help Center Section */}

      <Help
        buttonLabel="Chat Support"
        href="https://wa.me/9188545454"
        isExternalLink={true}
      />
      <br></br>
      <br />
      <Footer />
    </div>
  );
};

export default GrievancePolicy;
