import React from "react";
import "./Failmsg.css";
import failuregif from "../../assets/Image/Failure.gif";

const Failmsg = () => {
  return (
    <div>
      <div className="Fail-container">
        <img src={failuregif} alt="Success" className="Fail-gif" />
        <h2 className="Fail-text">
          Sorry, an error
          <br /> occurred
        </h2>
      </div>
    </div>
  );
};

export default Failmsg;
