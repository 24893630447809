import React, { useState, useEffect } from "react";
import "./ContactUs.css";
import Help from "../../Components/Help/Help.jsx";
import Footer from "../../Components/Footer.jsx";
import fbicon from "../../assets/Image/fb.png";
import insta from "../../assets/Image/insta.png";
import twitter from "../../assets/Image/Twitter.png";
import Header from "../../Components/Header.jsx";
import axios from "axios";
import { resources } from "../../Components/Resources/Resources.js";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const ContactUs = () => {
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState("");
  const [industryType, setIndustryType] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [name, setName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleMapClick = () => {
    window.open("https://www.google.com/maps", "_blank");
  };

  const location = useLocation();

  useEffect(() => {
    // Function to send page view information to Google Analytics
    const trackPageView = (url) => {
      window.gtag("config", "G-XNZ6JS7Y9J", {
        page_path: url,
      });
    };

    // Track page view when the location changes
    trackPageView(location.pathname);
  }, [location]);

  // Validate Email
  const validateEmail = (value) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(value);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (!validateEmail(value)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setPhone(value);
    }

    if (value.length !== 10) {
      setPhoneError("Phone number must be 10 digits.");
    } else {
      setPhoneError("");
    }
  };

  const handleClick = async () => {
    let formIsValid = true;

    if (!validateEmail(email)) {
      setEmailError("Please enter a valid email address.");
      formIsValid = false;
    }

    if (phone.length !== 10) {
      setPhoneError("Phone number must be 10 digits.");
      formIsValid = false;
    }

    if (
      !companyName ||
      !industry ||
      !industryType ||
      !name ||
      !email ||
      !phone ||
      !message
    ) {
      alert("Please fill in all fields.");
      formIsValid = false;
    }
    if (!isSubmitting) {
      console.log("handleClick===");

      alert("Please accept the permissions.");
      return;
      formIsValid = false;
    }
    if (!formIsValid) {
      return;
    }

    try {
      console.log(
        "Submitting details:",
        companyName,
        industry,
        industryType,
        name,
        email,
        phone,
        message
      );

      const response = await axios.post(
        resources.BaseUrl + `MerchantContactUs/merchantconatctusdetails`,
        {
          companyName,
          industry,
          industryType,
          name,
          email,
          phone,
          message,
        }
      );

      console.log("Response after saving details:", response?.data);

      if (response.status >= 200 && response.status < 300) {
        console.log("Data successfully stored:", response.data);
        alert("Your details submitted successfully!");
        setCompanyName("");
        setIndustry("");
        setIndustryType("");
        setEmail("");
        setName("");
        setMessage("");
        setPhone("");
        setIsSubmitting(false);
      } else {
        console.log("Failed to store data:", response.data);
        alert("There was a problem saving your details. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error?.message);
      alert("An error occurred while submitting the form. Please try again.");
    }
  };

  return (
    <div
      style={{ overflowX: "hidden", background: "white" }}
      className="contact-page-container"
    >
      <Helmet>
        <link rel="canonical" href="https://ntar.com/ContactUs/" />
      </Helmet>
      <section className="contact-form-section">
        <h4>Get Started</h4>
        <h2>Get in touch with us</h2>
        <h2>We're here to assist you.</h2>
        <div className="social-icons">
          <a
            href="https://www.facebook.com/ntarofficial/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="fbicon" src={fbicon} alt="fbicon" />
          </a>
          <a
            href="https://www.instagram.com/ntarofficial/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="fbicon" src={insta} alt="fbicon" />
          </a>
          <a
            href="https://twitter.com/Ntarofficial"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="fbicon" src={twitter} alt="fbicon" />
          </a>
        </div>

        <div className="contact-form">
          <input
            type="text"
            placeholder="Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className="form-input"
          />
          <input
            type="text"
            placeholder="Industry"
            value={industry}
            onChange={(e) => setIndustry(e.target.value)}
            className="form-input"
          />
          <select
            value={industryType}
            onChange={(e) => setIndustryType(e.target.value)}
            className="form-input"
          >
            <option value="" disabled>
              Select Industry Type
            </option>
            <option value="Technology">Technology</option>
            <option value="Finance">Finance</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Manufacturing">Manufacturing</option>
            <option value="Retail">Retail</option>
          </select>

          <input
            type="text"
            placeholder="Your Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="form-input"
          />

          <div>
            <input
              type="email"
              placeholder="Email Address"
              className="form-input"
              value={email}
              onChange={handleEmailChange}
              required
            />
            {emailError && <p className="error-message">{emailError}</p>}
          </div>

          <div>
            <input
              type="tel"
              placeholder="Phone Number"
              className="form-input"
              value={phone}
              onChange={handlePhoneChange}
              maxLength="10"
            />
            {phoneError && <p className="error-message">{phoneError}</p>}
          </div>

          <textarea
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="form-textarea"
          ></textarea>
        </div>

        <div class="checkbox-container">
          <input
            type="checkbox"
            id="customCheckbox"
            checked={isSubmitting}
            onChange={() => setIsSubmitting(!isSubmitting)}
          />
          <label for="customCheckbox" class="custom-checkbox"></label>
          <span class="checkbox-label">
            I agree to receive occasional informational and/or promotional
            messages from NTAR Bizz via RCS or SMS. By proceeding, I acknowledge
            and accept the Terms of Service and Privacy Policy
          </span>
        </div>
        <div className="sub-button">
          <button className="contact-submit-button" onClick={handleClick}>
            submit
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_6_14)">
                <path
                  d="M10.0501 5.3846L11.8529 3.58179L15.8247 7.49728C15.8247 7.49728 17.543 8.9339 17.543 10.5959C17.543 12.2578 15.8247 13.6381 15.8247 13.6381L11.8529 17.5818L10.0782 15.8071L14.7303 11.1551C15.0389 10.8465 15.0382 10.3459 14.7288 10.0382L10.0501 5.3846Z"
                  fill="white"
                />
                <path
                  d="M4.05007 5.3846L5.85289 3.58179L9.82472 7.49728C9.82472 7.49728 11.543 8.9339 11.543 10.5959C11.543 12.2578 9.82472 13.6381 9.82472 13.6381L5.85289 17.5818L4.07824 15.8071L8.73029 11.1551C9.03889 10.8465 9.03822 10.3459 8.72878 10.0382L4.05007 5.3846Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_6_14">
                  <rect width="21.6" height="21.6" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          {/* </a> */}
        </div>
      </section>

      {/* Contact Info Section */}
      <section className="contact-info-section">
        <div className="contact-info-container">
          <div>
            <h4 style={{ fontFamily: "Youth" }}>Contact Info</h4>
            <h3 style={{ color: "black" }}>
              We are always<br></br> happy to assist you
            </h3>
          </div>
          <div className="contact-info">
            <div className="info-box1">
              <h4>Email Address</h4>
              <p className="Hello">hello@ntarbizz.com</p>
              <p className="mon-hr">
                Assistance hours: <br />
                Monday - Friday 9:30 am to 6:30 pm
              </p>
            </div>
            <div className="info-box2">
              <h4>Contact Number</h4>
              <p className="Hello">+91 9985099850</p>
              <p className="mon-hr">
                Assistance hours: <br />
                Monday - Friday 9:30 am to 6:30 pm
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="map-section">
        <div className="map-placeholder" onClick={handleMapClick}>
          <iframe
            className="map-big"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1904.27521428525!2d78.56958933887087!3d17.337227295885352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcba1bba16c0f95%3A0xbe1311e3ba8dc03c!2sVCare%20Hair%20And%20Skin%20Clinic%20-%20LB%20Nagar!5e0!3m2!1sen!2sin!4v1724308103875!5m2!1sen!2sin"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </section>

      <Help
        buttonLabel="Chat Support"
        href="https://wa.me/9188545454"
        isExternalLink={true}
      />
      <br />
      <Footer />
    </div>
  );
};

export default ContactUs;
