import React, { useState,useEffect } from "react";
import "./Creditcardfaq.css";
import Help from "../../Components/Help/Help.jsx";
import Footer from "../../Components/Footer.jsx";
import book from "../../assets/Image/book.png";
import Education from "../../assets/Image/education.png";
import goldsavings from "../../assets/Image/goldsav.png";
import Eligibility from "../../assets/Image/Eligibility.png";
import Accountreg from "../../assets/Image/Accountreg.png";
import Credentials from "../../assets/Image/credentialsconfi.png";
import ntarservices from "../../assets/Image/ntarservices.png";
import userobligations from "../../assets/Image/userobligations.png";
import Fee from "../../assets/Image/fee.png";
import { useLocation } from "react-router-dom";

const Creditcardfaq = () => {
  const [selectedSection, setSelectedSection] = useState("Consent");
  const location = useLocation();
  useEffect(() => {
    const trackPageView = (url) => {
      window.gtag("config", "G-XNZ6JS7Y9J", {
        page_path: url,
      });
    };
    trackPageView(location.pathname);
  }, [location]);
  const sections = {
    Consent: {
      icon: book,
      subtitle: "User Agreement and Consent:",
      content: (
        <>
          <h3>Credit Card Bill Payment Terms</h3>
          <p>
            <strong>Acceptance of Terms:</strong> By using the credit card bill
            payment service on NTAR Bizz, you should agree to and accept NTAR
            Bizz terms and conditions.
          </p>
          <p>
            <strong>Consent to Electronic Communications:</strong> You consent
            to receive all communications regarding the credit card bill payment
            service electronically, including notifications, agreements, and
            disclosures.
          </p>
          <p>
            <strong>Service Usage:</strong> You agree to use the credit card
            bill payment service solely and in accordance with these terms.
          </p>
          <p>
            <strong>Accuracy of Information:</strong> You agree to provide
            accurate and complete information for processing credit card bill
            payments.
          </p>
          <p>
            <strong>Modification of Terms:</strong> NTAR Bizz reserves the right
            to modify these terms and conditions at any time. Continued use of
            the service constitutes your acceptance of any changes.
          </p>
        </>
      ),
    },
    " Eligibility": {
      icon: goldsavings,
      subtitle: "Eligibility",
      content: (
        <>
          <h3>Credit Card Bill Payment Requirements</h3>
          <ul>
            <li>
              <strong>Age Requirement:</strong> Users must be at least 18 years
              old to use the credit card bill payment service.
            </li>
            <li>
              <strong>Valid Account:</strong> Users must have a registered and
              verified account on the NTAR Bizz app to access the bill payment
              service.
            </li>
            <li>
              <strong>Active Credit Card:</strong> Users must possess an active
              credit card with a billing account that can be paid through the
              NTAR Bizz app.
            </li>
            <li>
              <strong>Compliance with Terms:</strong> Users must agree to and
              comply with the terms and conditions set forth by NTAR Bizz for
              using the service.
            </li>
            <li>
              <strong>Geographic Restrictions:</strong> The service may only be
              available in specific regions or countries as determined by NTAR
              Bizz.
            </li>
            <li>
              <strong>Sufficient Funds:</strong> Users must ensure there are
              sufficient funds in their account to cover the payment amount and
              any associated fees.
            </li>
            <li>
              <strong>Technical Requirements:</strong> Users must have a
              compatible device and internet connection to access and use the
              NTAR Bizz app effectively.
            </li>
            <li>
              <strong>Compliance with Financial Regulations:</strong> Users must
              comply with all applicable financial regulations and laws
              governing the use of payment services in their jurisdiction.
            </li>
          </ul>
        </>
      ),
    },

    "Fees and Charges": {
      icon: Eligibility,
      subtitle: "Fees and Charges",
      content: (
        <>
          <h3>Fees and Charges</h3>
          <p>
            You are responsible for any fees that may apply to certain
            transactions or the use of the NTAR Bizz App. The following covers,
            but is not limited to:
          </p>
          <br />
          <ul>
            <li>Service Charges</li>
            <li>Transaction Fees</li>
            <li>
              Other Costs associated with the use of NTAR Bizz App services
            </li>
          </ul>
          <br />
          <p>
            These costs could change based on the type of transaction. It is
            your responsibility to review and understand the fee structure
            before engaging in credit card bill payments. By using the NTAR Bizz
            App, you agree to pay any applicable fees and charges in a timely
            manner, as specified in the terms and conditions provided by NTAR
            Bizz.
          </p>
        </>
      ),
    },

    "Payment Processing": {
      icon: Accountreg,
      subtitle: "Payment Processing:  ",
      content: `When using the NTAR Bizz app for credit card bill payments, users authorize the app to process transactions on their behalf. Payments are initiated based on the user's instructions and are subject to the availability of sufficient funds in their account. NTAR Bizz employs secure payment processing methods to ensure the safety of each transaction, and users will receive confirmation upon successful payment submission. It is important for users to review their payment details before confirmation to prevent errors. Payments are typically processed promptly; however, users should allow sufficient time for processing to avoid any late fees or penalties from their credit card issuer.
`,
    },
    "Security and Privacy": {
      icon: Credentials,
      subtitle: "Security and Privacy:",
      content: `At NTAR Bizz, safeguarding your information is our top priority when using the credit card bill payment service. We employ advanced encryption technologies and secure servers to protect your personal and financial data during transactions. Your private data, including credit card information and account credentials, is stored and used solely for processing transactions and providing updates on your upcoming payments.`,
    },
    "Resolution and Liability ": {
      icon: ntarservices,
      subtitle: "Dispute Resolution and Liability",
      content: (
        <>
          <h3>Dispute Resolution and Liability</h3>
          <p>
            "Dispute Resolution and Liability" in the context of terms and
            conditions refers to how disagreements or disputes between the user
            (consumer) and the service provider (NTAR Bizz in this case) will be
            handled, and outlines the responsibilities and liabilities of each
            party in such situations.
          </p>
          <h4>Key Points Typically Covered:</h4>
          <ul>
            <li>
              <strong>Service Interruptions:</strong> NTAR Bizz is not liable
              for any disruptions in service due to technical issues,
              maintenance, or other unforeseen circumstances.
            </li>
            <li>
              <strong>No Refund Policy:</strong> NTAR Bizz does not provide
              refunds for any amounts lost during the process of making credit
              card bill payments. Users acknowledge that once a payment is
              initiated, NTAR Bizz is not liable for any resulting losses.
            </li>
            <li>
              <strong>User Responsibility:</strong> Users are responsible for
              ensuring the accuracy of payment details and for any consequences
              resulting from incorrect information provided.
            </li>
            <li>
              <strong>Changes to Terms:</strong> NTAR Bizz reserves the right to
              modify these terms, and users will be notified of any changes that
              may affect dispute resolution or liability.
            </li>
          </ul>
          <p>
            These terms are designed to protect both parties' interests while
            ensuring a fair and efficient process for resolving disputes that
            may arise from the use of the credit card bill payment service.
          </p>
        </>
      ),
    },
  };

  return (
    <>
      <div style={{ overflowX: "hidden", background: "white" }}>
        <div style={{ overflowX: "hidden" }} className="faqcredit-container">
          <aside className="sidebar">
            <ul>
              {Object.keys(sections).map((section, index) => (
                <React.Fragment key={index}>
                  <li>
                    <li key={section}>
                      <a
                        href="#"
                        onClick={() => setSelectedSection(section)}
                        style={{
                          fontWeight:
                            selectedSection === section ? "bold" : "normal",
                        }}
                      >
                        <img
                          src={sections[section].icon}
                          alt={section}
                          className="sidebar-icon"
                        />
                        {section}
                      </a>
                    </li>
                  </li>
                  <hr />
                </React.Fragment>
              ))}
            </ul>
          </aside>

          <section className="contentcreditcard">
            <div className="disclaimer-section">
              <p style={{ margin: "0px" }}>
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="/Termsandconditions"
                >
                  <span style={{ fontFamily: "Youth_bold", fontSize: "13px" }}>
                    Terms&Conditions / Credit Card{" "}
                  </span>
                </a>
                / {selectedSection}
              </p>
              <h1 className="termscond">
                {sections[selectedSection].subtitle}
              </h1>
              <br />

              <h3>{sections[selectedSection].title}</h3>
              <p>{sections[selectedSection].content}</p>
            </div>
          </section>
        </div>
        <Help
          buttonLabel="Chat Support"
          href="https://wa.me/9188545454"
          isExternalLink={true}
        />
        <br />
        <Footer />
      </div>
    </>
  );
};

export default Creditcardfaq;
