import React, { useState, useEffect } from "react";
import "./style/Header.css";
import mainlogo from "../assets/Image/mainlogo.png";
import menu from "../assets/Image/menu.webp";
import Headernav1 from "./Headernav1/Headernav1";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const isTransparent =
    // location.pathname === "/Ntarpay" || location.pathname === "/Education";
    window.location.pathname === "/Ntarpay";
  const [activeTab, setActiveTab] = useState("NTAR Bizz");
  useEffect(() => {
    // Detect if screen width is mobile size (you can adjust the width breakpoint)
    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
      setActiveTab("Business"); // Set "Business" as default for mobile
    }
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (popoverOpen || menuOpen || isOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [popoverOpen, menuOpen, isOpen]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsMobile(width <= 768 || (width >= 768 && width <= 1024)); // Includes iPads in the range
    };

    handleResize(); // Check initial width
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="desktop_cont">
        <div className="header-top">
          <div className="header-menu">
            <div>
              <span
                className="active_tab"
                style={{
                  backgroundColor:
                    activeTab === "NTAR Bizz" ? "#000" : "transparent", // Active tab bg color
                  color: "white",
                  padding: "20px",
                  borderRadius: "5px",
                }}
                onClick={() => setActiveTab("NTAR Bizz")}
              >
                {isMobile ? "Business" : "NTAR Bizz"}
              </span>

              <a
                style={{
                  textDecoration: "none",
                  color: "white",
                  padding: "20px",
                  marginLeft: "10px",
                  backgroundColor:
                    activeTab === "Customer" ? "#000" : "transparent",
                  borderRadius: "5px",
                }}
                href="https://www.ntar.com/"
                target="_blank"
                onClick={() => setActiveTab("Customer")}
              >
                <span
                  style={{
                    color: "white",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  Customer
                </span>
              </a>

              <span
                style={{
                  color: "white",
                  fontSize: "15px",
                  fontWeight: "500",
                  padding: "20px",
                  marginLeft: "10px",
                  backgroundColor:
                    activeTab === "Community" ? "#000" : "transparent",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => setActiveTab("Community")}
              >
                Community
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="phone_con">
        <div className="orange_header">
          <div className="orange_row">
            {/* NTAR Tab */}
            <div
              className="black_active"
              style={{
                backgroundColor: activeTab === "Ntar" ? "#000" : "transparent", // Active tab background
                color: activeTab === "Ntar" ? "#fff" : "#000", // Change text color based on active state
                padding: "6px",
              }}
              onClick={() => setActiveTab("Ntar")}
            >
              <p style={{ margin: "1px" }}>Ntar</p>
            </div>

            {/* Business Tab */}
            <div
              className="black_active_cus"
              style={{
                backgroundColor:
                  activeTab === "Business" ? "#000" : "transparent",
                color: activeTab === "Business" ? "#fff" : "#000",
                padding: "6px",
              }}
              onClick={() => setActiveTab("Business")}
            >
              <p style={{ margin: "1px" }}>Business</p>
            </div>

            {/* Customer Tab */}
            <a
              style={{
                textDecoration: "none",
                color: "white",
              }}
              href="https://www.ntar.com/"
              target="_blank"
            >
              <p
                style={{
                  margin: "1px",
                  backgroundColor:
                    activeTab === "Customer" ? "#000" : "transparent",
                  color: activeTab === "Customer" ? "#fff" : "#000",
                  padding: "6px",
                  cursor: "pointer",
                }}
                onClick={() => setActiveTab("Customer")}
              >
                Customer
              </p>
            </a>

            {/* Community Tab */}
            <p
              style={{
                margin: "1px",
                backgroundColor:
                  activeTab === "Community" ? "#000" : "transparent",
                color: activeTab === "Community" ? "#fff" : "#000",
                padding: "6px",
                cursor: "pointer",
              }}
              onClick={() => setActiveTab("Community")}
            >
              Community
            </p>
          </div>
        </div>
      </div>

      {/* <div className={`header-container ${isScrolled ? "scrolled" : ""}`}>
        <div className="header-bottom"> */}
      <div
        className={`header-container ${isScrolled ? "scrolled" : ""} ${
          isTransparent ? "transparent" : ""
        }`}
      >
        <div
          className={`header-bottom ${isScrolled ? "scrolled" : ""} ${
            isTransparent ? "transparent" : ""
          }`}
        >
          <a
            style={{ color: "white", textDecoration: "none" }}
            href="/"
            className="header-logo"
          >
            <img src={mainlogo} alt="Small Icon" className="small-icon" />
            <span className="logo-icon"></span> NTAR{" "}
            <span className="bizz" style={{ marginLeft: "5px" }}>
              Bizz
            </span>
          </a>

          <div className="header-links">
            <span onClick={togglePopover}>Features</span>
            <a
              style={{ textDecoration: "none", color: "white" }}
              href="/Comingsoon"
            >
              <span>Use Case</span>
            </a>

            <a
              style={{ textDecoration: "none", color: "white" }}
              href="/Comingsoon"
            >
              <span>Industries</span>
            </a>

            <a
              style={{ textDecoration: "none", color: "white" }}
              href="/Aboutus"
            >
              <span>Why NTAR Bizz</span>
            </a>

            <a
              style={{ textDecoration: "none", color: "white" }}
              href="/Comingsoon"
            >
              <span>Stories</span>
            </a>

            <a
              style={{ textDecoration: "none", color: "white" }}
              href="/Comingsoon"
            >
              <span>Careers</span>
            </a>
          </div>

          {popoverOpen && (
            <>
              <div className="overlay" onClick={togglePopover}></div>
              <div className="popover-container">
                <div className="popover-content">
                  <Headernav1 />
                </div>
              </div>
            </>
          )}

          <div className="header-actions">
            <a className="black_ntar"
              style={{ textDecoration: "none" }}
              href="https://play.google.com/store/apps/details?id=com.ntarbiz.business&pcampaignid=web_share"
              target="blank"
            >
              <button className="header-btn">Let's NTAR</button>
            </a>
            <div className="theme-toggle">
              <div className="hamburger-menu" onClick={toggleMenu}>
                <img src={menu} alt="Menu" />
              </div>
            </div>
          </div>
        </div>

        {menuOpen && (
          <>
            <div className="overlay" onClick={toggleMenu}></div>
            <div className="dropdown-scrollable">
              <div className="header-dropdown">
                <span onClick={toggleDropdown}>
                  Features
                  {isOpen ? (
                    <svg width="18" height="12" viewBox="0 0 16 10" fill="none">
                      <path
                        d="M13.5684 0.985899L15.5 2.91749L11.3048 7.17302C11.3048 7.17302 9.76559 9.01407 7.98491 9.01407C6.20423 9.01407 4.72535 7.17302 4.72535 7.17302L0.5 2.91749L2.40141 1.01608L7.38574 6.00041C7.71639 6.33106 8.2527 6.33034 8.58246 5.9988L13.5684 0.985899Z"
                        fill="#3203EA"
                      />
                    </svg>
                  ) : (
                    <svg width="18" height="12" viewBox="0 0 16 10" fill="none">
                      <path
                        d="M2.4316 9.0141L0.5 7.08251L4.69522 2.82698C4.69522 2.82698 6.23441 0.985931 8.01509 0.985931C9.79577 0.985931 11.2746 2.82698 11.2746 2.82698L15.5 7.08251L13.5986 8.98392L8.61426 4.99959C8.28361 4.66894 7.7473 4.66966 7.41754 5.0012L2.4316 9.0141Z"
                        fill="#3203EA"
                      />
                    </svg>
                  )}
                </span>

                {isOpen && <Headernav1 />}

                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                    width: "100%",
                  }}
                  href="/Comingsoon"
                >
                  <span>Use Case</span>
                </a>
                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                    width: "100%",
                  }}
                  href="/Comingsoon"
                >
                  <span>Industries</span>
                </a>
                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                    width: "100%",
                  }}
                  href="/Aboutus"
                >
                  <span>Why NTAR Bizz</span>
                </a>
                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                    width: "100%",
                  }}
                  href="/Comingsoon"
                >
                  <span>Stories</span>
                </a>

                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                    width: "100%",
                  }}
                  href="/Comingsoon"
                >
                  <span>Careers</span>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Header;
