import React, { useEffect, useRef } from "react";
import "./Home.css";
import scrolldata from "../../assets/Image/scroll-data.webp";
import scrollinvoice1 from "../../assets/Image/maskgroup.png";
import scrollinvoice2 from "../../assets/Image/electric.png";
import scrollinvoice3 from "../../assets/Image/utilitybills.png";
import scrollinvoice4 from "../../assets/Image/payrent.png";
import scrollinvoice5 from "../../assets/Image/posdev.png";
import scrollinvoice6 from "../../assets/Image/inventory.png";
import scrollinvoice7 from "../../assets/Image/customers.png";
import scrollinvoice8 from "../../assets/Image/dashboard.png";
import scrollinvoice9 from "../../assets/Image/Management.png";
import scrollinvoice10 from "../../assets/Image/bbps.png";
import scrollinvoice11 from "../../assets/Image/rent.png";
import scrollinvoice12 from "../../assets/Image/expenses.png";
import lineup from "../../assets/Image/lineup.webp";
import Features from "../../Components/Features";
import Keyfeatures from "../../Components/Keyfeatures";
import ScrollFeature from "../../Components/ScrollFeature";
import Ownerconnection from "../../Components/Ownerconnection";
import Clientsays from "../../Components/Clientsays";
import Wearehere from "../../Components/Wearehere";
import Footer from "../../Components/Footer";
import Success from "../../Components/Success";
import Header from "../../Components/Header";
import curanimation from "../../assets/Image/cur-animation.png";
import Animation from "../../Components/Animation/Animation";
import { Helmet } from 'react-helmet';
import { useLocation } from "react-router-dom";

const Home = () => {
  const scrollRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const trackPageView = (url) => {
      window.gtag("config", "G-XNZ6JS7Y9J", {
        page_path: url,
      });
    };
    trackPageView(location.pathname);
  }, [location]);

  // Auto scroll logic
  // useEffect(() => {
  //   const scroll = () => {
  //     if (scrollRef.current) {
  //       scrollRef.current.scrollBy({ left: 5, behavior: "smooth" }); 
  //       if (
  //         scrollRef.current.scrollLeft + scrollRef.current.offsetWidth >=
  //         scrollRef.current.scrollWidth
  //       ) {
  //         scrollRef.current.scrollTo({ left: 0, behavior: "smooth" });
  //       }
  //     }
  //   };
  //   const intervalId = setInterval(scroll, 16);
  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    const scrollContainer = scrollRef.current;
    let animationFrameId; // Store the animation frame ID for cleanup

    const scroll = () => {
      if (scrollContainer) {
        // Scroll by a small amount, you can adjust this value
        const scrollAmount = 15; // Adjust this for faster or slower scrolling
        scrollContainer.scrollBy({ left: 10, scrollAmount, behavior: 'smooth' });

        // Check if we've reached the end of the scroll container
        if (
          scrollContainer.scrollLeft + scrollContainer.offsetWidth >=
          scrollContainer.scrollWidth
        ) {
          scrollContainer.scrollTo({ left: 5, behavior: 'smooth' });
        }

        // Request the next animation frame
        animationFrameId = requestAnimationFrame(scroll);
      }
    };

    // Start the scrolling
    animationFrameId = requestAnimationFrame(scroll);

    // Cleanup function to cancel the animation frame
    return () => cancelAnimationFrame(animationFrameId);
  }, []);

  const iconItems = [
    { label: "Invoice", img: scrollinvoice1 },
    { label: "Electricity", img: scrollinvoice2 },
    { label: "Utility Bills", img: scrollinvoice3 },
    { label: "Pay Rent", img: scrollinvoice4 },
    { label: "POS Device", img: scrollinvoice5 },
    { label: "Inventory", img: scrollinvoice6 },
    { label: "Customers", img: scrollinvoice7 },
    { label: "Dashboards", img: scrollinvoice8  },
    { label: "Management", img: scrollinvoice9 },
    { label: "BBPS", img: scrollinvoice10 },
    { label: "Rent", img: scrollinvoice11 },
    { label: "Expenes", img: scrollinvoice12 },
  ];

  const repeatedIconItems = Array(100).fill(iconItems).flat();
  return (
    <>
     <Helmet>
        <title>NTAR Business - Online Bill Pay Services for Business</title>
        <meta
          name="description"
          content="NTAR Business: Secure online bill pay Merchant bill payment solutions with automated invoicing, payment tracking & financial management."
        />
        <meta
          name="keywords"
          content="merchant bill payment, bill pay business, business bill pay service"
        />
        {/* Canonical URL for this page */}
        <link rel="canonical" href="https://ntarbizz.com/" />
      </Helmet>
  
      <div className="first_margintop" style={{ backgroundColor: "black" }}>
        <div className="home-container">
          <div className="navbar">
          </div>
          <div className="main-section">
            <h1>
              NTAR <span className="highlight">Designed</span> <br />
              for Next <br />
              <span className="highlight">Billion</span> Business
            </h1>

            <div className="icon-scroll" ref={scrollRef}>
              {repeatedIconItems.map((item, index) => (
                <div className="icon-item" key={index}>
                  <img src={item.img} alt={item.label} className="icon-image" />
                  <div>{item.label}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="content-section">
            <img src={scrolldata} alt="Content Example" />
            <img src={curanimation} alt="Content Example" />
          </div>
        </div>
        <div className="product-lineup-section">
          <h2 className="product-title">
            {" "}
            Discover our product lineup,<br></br> Built to boost your business
            efficiency
          </h2>
          <p className="product-subtitle">
            Step Into a new world that elevates your business to <span className="hundred">100% </span>success!
          </p>

          <div className="product-image-container">
            <img src={lineup} alt="lineup" className="product-image" />
          </div>
        </div>
        <Animation/>
        <Keyfeatures />
        <ScrollFeature />
        <div>
          <Clientsays />
        </div>
        <Wearehere />
        <div style={{ marginTop: "-10%" }}>
          {" "}
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Home;
