import React, { useState, useEffect } from "react";
import "./RentalFaq.css";
import Help from "../../Components/Help/Help.jsx";
import Footer from "../../Components/Footer.jsx";
import book from "../../assets/Image/book.png";
import Education from "../../assets/Image/education.png";
import goldsavings from "../../assets/Image/goldsav.png";
import Eligibility from "../../assets/Image/Eligibility.png";
import Accountreg from "../../assets/Image/Accountreg.png";
import Credentials from "../../assets/Image/credentialsconfi.png";
import ntarservices from "../../assets/Image/ntarservices.png";
import userobligations from "../../assets/Image/userobligations.png";
import Fee from "../../assets/Image/fee.png";
import { useLocation } from "react-router-dom";

const RentalFaq = () => {
  const location = useLocation();

  useEffect(() => {
    const trackPageView = (url) => {
      window.gtag("config", "G-XNZ6JS7Y9J", {
        page_path: url,
      });
    };

    // Track page view when the location changes
    trackPageView(location.pathname);
  }, [location]);
  const [selectedSection, setSelectedSection] = useState(
    "General Terms&Conditions"
  );
  const sections = {
    "General Terms&Conditions": {
      icon: book,
      subtitle: "TermsConditions",
      content: (
        <>
          <p>
            NTAR Bizz introduces its registered users to an ideal and convenient
            way to make house and office rental payments, security deposits,
            etc., using its website and mobile app to their recipients,
            homeowners, and beneficiaries. Using this platform means you agree
            to our Term of Rental Payment Terms.
          </p>
          <br />
          <p>
            You confirm that there is an official and valid rental agreement
            between you and the recipient. The amount being transferred by you
            is completely accurate following this agreement. The user is
            responsible for ensuring the transaction’s authenticity and adding
            the correct recipient details. NTAR Bizz offers a platform to pay
            rent, security, and token payment easily. NTAR Bizz is not
            responsible or involved in the actual transaction between you and
            the recipient. Users must comply with relevant payment regulations
            and are responsible for any non-compliance or default. NTAR Bizz is
            not a bank and does not introduce banking services beyond
            facilitating these payments. Our motive is to make your rental and
            related payment completely simple and secure. We request our
            customers to always double-check all the details and understand the
            terms while using rental payment services.
          </p>
          <br />
          <p>
            These Rental Payment Terms & Conditions stand for the rental payment
            services introduced by NTAR Bizz. Being a user using this service
            indicates your acceptance of the following terms. If you do not
            agree to any section of these terms, you may discontinue the
            service.
          </p>
        </>
      ),
    },
    " Usage of NTAR Bizz": {
      icon: goldsavings,
      subtitle: "Usage of NTAR Bizz Rental Payment Service-",
      content: `The Rental Payment service allows you to make rental payments to property management companies and recipients. Users should provide only accurate payment information including the due date, the amount, and the recipient details. You authorize NTAR Bizz to process rental payments on your behalf.`,
    },

    "Tax Deduction": {
      icon: Eligibility,
      subtitle: "Tax Deduction",
      content: `It will be solely user’s responsibility to handle in case he/she needs to deduct tax for rent payment following the Income Tax Act, of 1961. NTAR Bizz would not be responsible regarding taking care of tax deduction obligations for you.`,
    },

    "Account Registration": {
      icon: Accountreg,
      subtitle: "Account Registration for Rental Payments ",
      content: `While registering to use the rental payment service, we (NTAR Bizz) expect you to provide only accurate and complete information. You should keep your registration information up-to-date. Keep your account’s credentials confidential.
`,
    },
    "Eligibility Criteria ": {
      icon: Credentials,
      subtitle:
        "Guidelines for Eligibility in Rental Payment Service/ Eligibility Criteria for Accessing Rental Payment Service",
      content: `To use NTAR Bizz rental payment services, users must be at least 18 years old. Moreover, users must not be suspended or removed from NTAR Bizz platform. Continuing the service, you agree that you meet these requirements and hold the authority to get into this agreement. To use this service, users must have a registered account with NTAR Bizz.`,
    },
    "KYC Documentation ": {
      icon: ntarservices,
      subtitle: "KYC Documentation ",
      content: `You are responsible for providing the authenticity of your transactions. We may request you to verify some documents like rental agreement, Aadhaar card, PAN card, etc.`,
    },
    Overview: {
      icon: userobligations,
      subtitle: "Overview of Rental Payment Services ",
      content: `NTAR Bizz allows users to pay various bills including rent. Using NTAR Bizz rental payment service, users can easily pay their shop rent, house rent, office rent, etc. The motto of the rental payment service is to simplify the entire process by consolidating different payments into one platform.`,
    },
    "Payment Protocols": {
      icon: Fee,
      subtitle: "Payment Protocols and Refund Policy for Rentals  ",
      content: `NTAR Bizz rental payment service does not permit any refunds or exchanges. Users must be responsible for ensuring the accuracy of details submitted for their transactions.`,
    },
    Processing: {
      icon: book,
      subtitle: "Processing of Rental Payments ",
      content: `Users can make payments easily by using our NTAR Bizz app. The processing is probably different as per the payment methods you pick up. To make rental payments without any hassles, users are responsible to hold sufficient funds in their account.`,
    },
    Acknowledgment: {
      icon: Education,
      subtitle: "Acknowledgment of Payment/ Confirmation of Transaction",
      content: `You will receive a payment confirmation via email or SMS once the transaction is processed. We expect users to check and verify the transaction details immediately upon receipt of the confirmation. In case you find any discrepancies, you should notify us (NTAR Bizz officials) within the stipulated time.`,
    },
    "Payment Failures": {
      icon: goldsavings,
      subtitle: "Uncompleted Rental Payments/ Rental Payment Failures",
      content: `In case a user's transaction fails, NTAR Bizz will send you a notification notifying you of the failure. Users will be responsible for re-initiating the payment.`,
    },
    "Handling Disagreements": {
      icon: Eligibility,
      subtitle: "Handling Disagreements/ Dispute Handling and Resolution",
      content: `Any disputes associated with rental payments should be resolved between you and the recipient. NTAR Bizz will provide you with important transaction details to assist in resolving disputes. NTAR Bizz is not responsible for any claims, damage occurring from rental payments, or disputes.  `,
    },
    "Privacy Assurance": {
      icon: Accountreg,
      subtitle:
        "Confidentiality and Data Safeguards/ Privacy Assurance and Secure Transactions",
      content: `NTAR Bizz adheres to maintaining its customer’s privacy and personal information. All sorts of rental payment transactions are encrypted and processed securely. Please go through NTAR Bizz's privacy policy to churn out more details on how your data is handled.`,
    },
    "Amendments To Terms ": {
      icon: Credentials,
      subtitle: "Amendments To Terms ",
      content: `NTAR Bizz holds the right to modify these Terms at any time. Users are expected to keep checking the updated terms from time to time. Continuing use of the Service after changes means you agree to the new terms.`,
    },
    Illegal: {
      icon: ntarservices,
      subtitle: "Illegal/Improper Use Of Rental Payment Service ",
      content: (
        <>
          <p>
            Users are expected not to use this service for anything illegal or
            improper as it is completely forbidden.
          </p>
          <ul>
            <li>
              False claims or imparting any wrong information is prohibited.
            </li>
            <li>
              This service does not allow disguising the origins of illegally
              obtained money.
            </li>
            <li>
              To fund or support terrorist activities is strictly forbidden.
            </li>
            <li>
              Users must not use this service to make payments for anything
              other than legitimate rental transactions.
            </li>
            <li>
              Not allowed to use the services to pay for illegal goods or
              services.
            </li>
          </ul>
        </>
      ),
    },
    "Service Termination": {
      icon: userobligations,
      subtitle: "Service Termination ",
      content: `NTAR Bizz holds the right to terminate or suspend your account or access to the services at any time without sending any notice or for any reason. Closing your account means you will not be able to use the service anymore.
`,
    },
    "Contact Information ": {
      icon: Fee,
      subtitle: "Contact Information ",
      content: `If you have any sort of questions or concerns regarding these Terms, you should contact NTAR’s Bizz officials via mail/website chat box/app chat box.
By using the rental payment services provided by NTAR Bizz, you acknowledge that you have read, understood, and agree to be bound by these Terms.`,
    },
  };

  return (
    <>
      <div style={{ overflowX: "hidden", background: "white" }}>
        <div style={{ overflowX: "hidden" }} className="faqrental-container">
          <aside className="sidebar">
            <ul>
              {Object.keys(sections).map((section, index) => (
                <React.Fragment key={index}>
                  <li key={section}>
                    <a
                      href="#"
                      onClick={() => setSelectedSection(section)}
                      style={{
                        fontWeight:
                          selectedSection === section ? "bold" : "normal",
                      }}
                    >
                      <img
                        src={sections[section].icon}
                        alt={section}
                        className="sidebar-icon"
                      />
                      {section}
                    </a>
                  </li>
                  <hr />
                </React.Fragment>
              ))}
            </ul>
          </aside>

          <section className="contentrental">
            <div className="disclaimer-section">
              <p style={{ margin: "0px" }}>
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="/Termsandconditions"
                >
                  <span style={{ fontFamily: "Youth_bold", fontSize: "13px" }}>
                    Terms&Conditions / Rental{" "}
                  </span>
                </a>
                / {selectedSection}
              </p>
              <h1 className="termscond">
                {sections[selectedSection].subtitle}
              </h1>
              <br />

              <h3>{sections[selectedSection].title}</h3>
              <p>{sections[selectedSection].content}</p>
            </div>
          </section>
        </div>
        <Help
          buttonLabel="Chat Support"
          href="https://wa.me/9188545454"
          isExternalLink={true}
        />
        <br />
        <Footer />
      </div>
    </>
  );
};

export default RentalFaq;
