import React from "react";
import "./style/Wearehere.css";
import Featuredicon from "../assets/Image/Featuredicon.png"; // Replace with the path to your icon image
import mail from "../assets/Image/mail.webp";
import msg from "../assets/Image/msgs.webp";
import faq from "../assets/Image/faq.webp";
import ntarhomebig from "../assets/Image/ntarhome-big2.webp"; // Replace with the path to your full-width image

const Wearehere = () => {
  return (
    <div>
      <div className="wearehere-container">
        <h2 className="wearehere-title">Meet your 24/7 support squad</h2>
        <div className="wearehere-grid">
          <a
            style={{ textDecoration: "none" }}
            href="/ContactUs"
            className="wearehere-item"
          >
            <img src={mail} alt="24/7 Support" className="wearehere-icon" />
            <h3>24/7 Email + Chat Support</h3>
            <p>Email us anytime – our 24/7 inbox is always open!.</p>
          </a>
          <a
            href="https://wa.me/9188545454"
            target="blank"
            style={{ textDecoration: "none" }}
            className="wearehere-item"
          >
            <img src={msg} alt="$500K+ in Perks" className="wearehere-icon" />
            <h3>Chat Support</h3>
            <p>
              {" "}
              Chat with us anytime – our support team is here around the clock
              to assist you
            </p>
          </a>
          <a
            style={{ textDecoration: "none" }}
            href="/FAQ"
            className="wearehere-item"
          >
            <img src={faq} alt="Concierge" className="wearehere-icon" />
            <h3>FAQ</h3>
            <p>
              Our FAQ to find the best answers and guidance for your questions
            </p>
          </a>
        </div>
        <a
          style={{ textDecoration: "none" }}
          href="https://play.google.com/store/apps/details?id=com.ntarbiz.business&pcampaignid=web_share"
          target="blank"
          className="full-width-image-container"
        >
          <img
            src={ntarhomebig}
            alt="Full Width"
            className="full-width-image"
          />
        </a>
      </div>
    </div>
  );
};

export default Wearehere;
