import React from "react";
import "./Successmsg.css";
import successgif from "../../assets/Image/Success.gif";

const Successmsg = () => {
  return (
    <div className="success-container">
      <img src={successgif} alt="Success" className="success-gif" />
      <h2 className="success-text">
        Payment
        <br /> Successful
      </h2>
    </div>
  );
};

export default Successmsg;
