import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./Faqsection.css";
import Help from "../../Components/Help/Help.jsx";
import Footer from "../../Components/Footer.jsx";
import book from "../../assets/Image/book.png";
import Education from "../../assets/Image/education.png";
import goldsavings from "../../assets/Image/goldsav.png";
import Eligibility from "../../assets/Image/Eligibility.png";
import Accountreg from "../../assets/Image/Accountreg.png";
import Credentials from "../../assets/Image/credentialsconfi.png";
import ntarservices from "../../assets/Image/ntarservices.png";
import userobligations from "../../assets/Image/userobligations.png";
import Fee from "../../assets/Image/fee.png";
import { Helmet } from "react-helmet";
import { GlobalContext } from "../../Components/GlobalContext/GlobalContext.jsx";
import { useNavigate } from "react-router-dom";

const Faqsection = (target, rel) => {
  const { activeHeader, setActiveHeader } = useContext(GlobalContext);
  const location = useLocation();
  const [selectedSection, setSelectedSection] = useState("Privacy Policy");

  useEffect(() => {
    const trackPageView = (url) => {
      window.gtag("config", "G-XNZ6JS7Y9J", {
        page_path: url,
      });
    };
    trackPageView(location.pathname);
  }, [location]);

  useEffect(() => {
    if (activeHeader) {
      if (activeHeader !== "Privacy Policy") {
        setSelectedSection(activeHeader);
      }
    }
  }, [activeHeader]);

  const sections = {
    "Privacy Policy": {
      icon: book,
      subtitle: "Privacy Policy",
      content: `Welcome to NTAR Bizz Private Limited (”NTAR Business," "we," "our," or "us"), NTAR Bizz Private Limited, a company incorporated under the Companies Act 1956, operates with its registered office located at 5-5 165/2/TF, beside Vanasthali Hills, Vanasthalipuram, Ranga Reddy, Hyderabad, Telangana, 500070. This Privacy Policy outlines how NTAR Bizz Private Limited accumulates, collects, and uses information through its websites, apps, and SMS notifications. By visiting or downloading any NTAR Bizz website or app, you agree to this Privacy Policy and the applicable terms and conditions of the respective services or products.
      
As a platform committed to safeguarding your privacy, NTAR Bizz respects your trust and prioritizes the protection of your personal information. We adhere to the highest standards to ensure your data remains secure.`,
    },

    "Legal Compliance": {
      icon: book,
      subtitle: "Legal Compliance",
      content: `The NTAR Business Privacy Policy is published and shall be construed in accordance with Indian laws and regulations, including but not limited to:

•  The Information Technology Rules, 2011
•  The Information Technology Act, 2000
•  The Aadhaar Act, 2016, and its amendments

Our products and services are designed for Indian customers, and the processing of your personal information will be subject to Indian laws. If you disagree with any part of our privacy practices, we advise you not to use our platform.
`,
    },

    "Information Collection": {
      icon: userobligations,
      subtitle: "Information Collection ",
      content: (
        <>
          <h3>Personal Information Collection</h3>
          <p>Users may be asked to share personal details such as:</p>
          <ul>
            <li>Name</li>
            <li>Photo</li>
            <li>Phone number</li>
            <li>Current email ID</li>
          </ul>

          <h3>KYC-Oriented Information</h3>
          <p>
            Users may be required to provide Know Your Customer (KYC)
            information, which may include:
          </p>
          <ul>
            <li>Email ID</li>
            <li>PAN card number</li>
            <li>Aadhaar details</li>
          </ul>

          <h3>Aadhaar Information</h3>
          <p>Users may need to provide Aadhaar information, such as:</p>
          <ul>
            <li>Aadhaar number or virtual ID</li>
          </ul>
          <p>
            This step ensures the authenticity of all shared information. <br />
            <strong>Note:</strong> Providing Aadhaar information is not
            compulsory; alternative options are available.
          </p>

          <h3>OTP Verification</h3>
          <p>
            Users may be required to share the One-Time Password (OTP) sent to
            their registered device by NTAR Bizz for verification purposes.
          </p>

          <h3>Collection Stages</h3>
          <p>We collect information at various stages, including:</p>
          <ul>
            <li>While exploring the NTAR Bizz platform.</li>
            <li>During registration on the NTAR Bizz platform.</li>
            <li>During transactions on the NTAR Bizz platform.</li>
            <li>
              Through official links, chats, emails, or notifications from the
              NTAR Bizz platform.
            </li>
            <li>During interactions with NTAR Bizz entities.</li>
          </ul>

          <h3>Third-Party Information Collection</h3>
          <p>We may also collect information from third parties, such as:</p>
          <ul>
            <li>
              Financial history and related information to verify and
              authenticate transactions.
            </li>
            <li>Demographic and photo information for KYC processing.</li>
          </ul>
        </>
      ),
    },

    "Merchant Information": {
      icon: goldsavings,
      subtitle: "Merchant Identification Information",
      content: `Personal Information Collection:

Users may be asked to share personal details such as:

•  Name
•  Photo
•  Phone number
Current email ID

KYC-Oriented Information:

Users may be required to provide Know Your Customer (KYC) information, which may include:

•  Email ID
•  PAN card number
•  Aadhaar details

Aadhaar Information:
Users may need to provide Aadhaar information, such as:

•  Aadhaar number or virtual ID
This step ensures the authenticity of all shared information.
Note: Providing Aadhaar information is not compulsory; alternative options are available.

OTP Verification:
Users may be required to share the One-Time Password (OTP) sent to their registered device by NTAR Bizz for verification purposes.

Collection Stages

•  We collect information at various stages, including:
•  While exploring the NTAR Bizz platform.
•  During registration on the NTAR Bizz platform.
•  During transactions on the NTAR Bizz platform.
•  Through official links, chats, emails, or notifications from the NTAR Business platform.
•  During interactions with NTAR Business entities.

Third-Party Information Collection
We may also collect information from third parties, such as:

•  Financial history and related information to verify and authenticate transactions.
•  Demographic and photo information for KYC processing.`,
    },

    "Cookies&Technologies": {
      icon: Eligibility,
      subtitle: "Cookies or Similar Technologies",
      content: `NTAR Bizz Private Limited uses cookies and similar technologies to enhance your experience on our platform. These technologies help us analyze site usage, improve service performance, track promotional effectiveness, and ensure a secure and trustworthy environment for all users. By using our platform, you consent to our use of cookies and similar technologies in accordance with this policy.`,
    },

    "Third-Party": {
      icon: userobligations,
      subtitle: "Third-Party Products, Services, or Websites",
      content: `Using or continuing NTAR Bizz products and services at the NTAR Bizz web portal or app means your personal information may be collected by us and it is governed by our privacy policy. Users are allowed to refer to their policy and terms of service to see how the collected Personal Information will be handled by those service providers.

NTAR Bizz introduces its users so that our service may come up with links to other web portals or applications while they explore our platform. For your knowledge, we mention that these third-party web portals or applications are governed by their distinguished privacy policies and they do not stand out of control.

If a user discontinues our servers, use of any Personal Information provided by you is governed by the privacy policy of that platform’s operator, you explored the sites. It means these policies have different elements from what our site does. Therefore, users should be responsible for reviewing those policies or seeking access to the policies from the domain owner before the process of using those applications or web portals.

Here, we announce that we do not accept any sort of responsibility or liability for the usage of your personal information by these third parties or their policies.`,
    },

    "Children's Information": {
      icon: Education,
      subtitle: "Children's Information",
      content: `NTAR Bizz Private Limited does not knowingly solicit or collect personal information from children under the age of 18. Our platform is intended for use only by individuals who can form legally binding contracts as per the Indian Contract Act, 1872. If you are under 18 years of age, you may access and use our platform or services only under the supervision of a parent, legal guardian, or any responsible adult. We strongly encourage parents and guardians to monitor their children’s activities online and ensure a safe and responsible experience on our platform.`,
    },
    "Services Policy": {
      icon: goldsavings,
      subtitle: "Services Policy:",
      content: `NTAR Bizz provides a wide range of financial services designed to streamline your business transactions and enhance your financial management experience. The services we offer are governed by this policy, which outlines how your data is used, stored, and protected while using these services.

NTAR Bizz PAY:

•  Bill payments
•  Single
•  Multi/Bulk
•  Rental & Credit Card
•  Single
•  Multi/Bulk

NTAR Bizz Choice:

•  Current Account Management
•  Manage
•  POS Devices, Soundbox, QR Code

NTAR Bizz Link:

•  Pay Contacts
•  Vendor Payments
•  Business Payments
•  Customer
•  Get Paid
•  Payment Collection
•  Settlements
•  Reconciliation
`,
    },
    "Changes to This Privacy Policy": {
      icon: Fee,
      subtitle: "Changes to This Privacy Policy:",
      content: `We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy.`,
    },
  };

  console.log("sections[selectedSection]===>", sections[selectedSection]);
  console.log("selectedSection==>", selectedSection);

  return (
    <>
      <div style={{ overflowX: "hidden", background: "white" }}>
        <Helmet>
          <link rel="canonical" href="https://ntarbizz.com/FAQ/" />
        </Helmet>
        <div style={{ overflowX: "hidden" }} className="faqprivacy-container">
          <aside className="sidebar">
            <ul>
              {Object.keys(sections).map((section, index) => (
                <React.Fragment key={index}>
                  <li>
                    <li key={section}>
                      <a
                        href="#"
                        onClick={() => setSelectedSection(section)}
                        style={{
                          fontWeight:
                            selectedSection === section ? "bold" : "normal",
                        }}
                      >
                        <img
                          src={sections[section].icon}
                          alt={section}
                          className="sidebar-icon"
                        />
                        {section}
                      </a>
                    </li>
                  </li>
                  <hr />
                </React.Fragment>
              ))}
            </ul>
          </aside>

          {selectedSection && (
            <section className="contentfaq">
              <div className="disclaimer-section">
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="/Privacypolicy"
                >
                  <p style={{ margin: "0px" }}>
                    <span
                      style={{ fontFamily: "Youth_bold", fontSize: "13px" }}
                    >
                      Privacy Policy /{" "}
                    </span>
                    {selectedSection}
                  </p>
                </a>

                <h1 className="termscond">
                  {sections[selectedSection]?.subtitle}
                </h1>
                <br />

                <h3>{sections[selectedSection]?.title}</h3>
                <p>{sections[selectedSection]?.content}</p>
              </div>
            </section>
          )}
        </div>
        <Help
          buttonLabel="Chat Support"
          href="https://wa.me/9188545454"
          isExternalLink={true}
        />
        <br />
        <br />
        <Footer />
      </div>
    </>
  );
};
export default Faqsection;
