import React, { useState, useEffect } from "react";
import "./Rechargebillfaq.css";
import Help from "../../Components/Help/Help.jsx";
import Footer from "../../Components/Footer.jsx";
import book from "../../assets/Image/book.png";
import Education from "../../assets/Image/education.png";
import goldsavings from "../../assets/Image/goldsav.png";
import Eligibility from "../../assets/Image/Eligibility.png";
import Accountreg from "../../assets/Image/Accountreg.png";
import Credentials from "../../assets/Image/credentialsconfi.png";
import ntarservices from "../../assets/Image/ntarservices.png";
import userobligations from "../../assets/Image/userobligations.png";
import Fee from "../../assets/Image/fee.png";
import { useLocation } from "react-router-dom";

const Rechargebillfaq = () => {
  const location = useLocation();

  useEffect(() => {
    // Function to send page view information to Google Analytics
    const trackPageView = (url) => {
      window.gtag("config", "G-XNZ6JS7Y9J", {
        page_path: url,
      });
    };

    // Track page view when the location changes
    trackPageView(location.pathname);
  }, [location]);
  const [selectedSection, setSelectedSection] = useState("Recharge & Bill");

  const sections = {
    "Recharge & Bill": {
      icon: book,
      subtitle: "Recharge & Bill Pay Services",
      content: `The purpose of publishing these terms and conditions is to regulate the use of Recharge and Bill Pay services enabled by NTAR Bizz Private Limited. By continuing to use NTAR Bizz Recharge & Pay Bills, you (referred to as "your," "user," or "you") agree to the mentioned Terms of Use, including agreeing to the General NTAR Bizz Terms and Conditions.`,
    },
    " Services Offered": {
      icon: goldsavings,
      subtitle: "Services Offered",
      content: `The Recharge & Pay Bills category includes clients/customers using utility services such as water, electricity, landline, pipe gas, broadband, cylinder, or recharge services, mobile prepaid, mobile postpaid, DTH, etc. NTAR Bizz wallet payment method is accepted, allowing users to make bill payments or recharges through the official NTAR Bizz app using a payment service through an aggregator or BBPOU (Bharat Bill Payment Operating Unit).`,
    },

    "Application of Terms": {
      icon: Eligibility,
      subtitle: "Application of Terms",
      content: `These Bill Pay T&Cs apply to you when you conduct transactions using NTAR Bizz to avail of the Recharge & Pay Bills service. NTAR Bizz reserves the right to make changes or modifications, add or remove portions of these Terms of Use at any time without prior written notice to its users. Users are responsible for reviewing these Terms of Use periodically for any updates or changes. Continued use of NTAR Bizz’s services implies acceptance of all changes to the policy.`,
    },

    "General Terms ": {
      icon: Accountreg,
      subtitle: "General Terms ",
      content: (
        <>
          <p>
            By using the NTAR Bizz Recharge and Bill Pay feature in the NTAR
            Bizz App, you agree to all terms and conditions. Please review the
            terms carefully before proceeding. Acceptance of these terms means
            you agree to follow all NTAR Bizz policies, including the Privacy
            Policy.
          </p>
          <h3>Terms of Using Recharge & Pay Bills</h3>
          <ul>
            <li>
              NTAR Bizz is a payment facilitator and should not be considered a
              party to the payments.
            </li>
            <li>
              NTAR Bizz enables payments for various services such as:
              <li>Mobile prepaid</li>
              <li>Mobile postpaid</li>
              <li>DTH</li>
              <li>
                Utility services (water, electricity, pipe gas, landline,
                broadband, cylinder, etc.)
              </li>
            </li>
            <li>
              Users can access these services under the "Recharge and Pay Bills"
              section in the NTAR Bizz app. Payments are processed through:
              <li>Aggregators with whom NTAR Bizz has agreements</li>
              <li>BBPOU (Bharat Bill Payment Operating Unit system)</li>
            </li>
          </ul>
        </>
      ),
    },
    "Recharge Getting Started  ": {
      icon: Credentials,
      subtitle: "Getting Started With Recharge and Pay Bills",
      content: (
        <>
          <ul>
            <li>
              To make recharges or pay bills, users must provide details such as
              their unique customer ID, subscription ID, bill number, registered
              mobile number, or official/registered phone number. These details
              are crucial for accurate payment processing.
            </li>
            <li>
              By using our service, you authorize NTAR Bizz to access, retrieve,
              share, use, and accumulate information associated with your
              account to facilitate Recharge & Bill Pay services. Users are
              responsible for ensuring the accuracy of the information provided.
            </li>
            <li>
              Users must keep account information updated as per the terms and
              conditions. Failure to do so may result in account suspension or
              restricted services. The amount to be paid or recharged is an
              agreement between you and the service provider, and NTAR Bizz is
              not responsible for verifying this amount.
            </li>
            <li>
              To utilize recharge and bill pay services, users must submit
              necessary information such as user ID, location/state, KYC
              information, or other personal details for tax/GST purposes. Users
              also authorize NTAR Bizz to communicate with third-party service
              providers and aggregators to process transactions.
            </li>
          </ul>
        </>
      ),
    },
    "User Responsibilities ": {
      icon: ntarservices,
      subtitle: "Charges and User Responsibilities",
      content: (
        <>
          <h3>Fees</h3>
          <ul>
            If you use third-party payment services or incur other data fees
            from third-party participants and billers, you may have to pay
            charges. NTAR Bizz is not responsible for these charges. By
            continuing to use NTAR Bizz services, you agree to these charges.
          </ul>

          <h3>User Obligations</h3>
          <ul>
            <strong>Track Bills and Subscriptions:</strong> Users should track
            their bills, recharge expiry dates, and subscription fees. NTAR Bizz
            is not responsible for technical issues or errors in retrieving
            bills.
            <br />
            <br />
            <strong>Verifying Transactions:</strong> Users must check their
            transaction history or notifications to confirm if a transaction was
            successful or failed.
            <br />
            <br />
            <strong>Pay Charges:</strong> Users must be aware that any charges
            from the biller for Recharge & Pay Bills Services can be deducted
            from their account or added to their bill/subscription fees.
            <br />
            <br />
          </ul>
        </>
      ),
    },
    "Errors and Disclaimers": {
      icon: userobligations,
      subtitle: "User Errors and Disclaimers",
      content: (
        <>
          <h3>User Mistakes</h3>
          <ul>
            Sending a payment to the wrong person or biller, paying twice, or
            sending the wrong amount is the user’s responsibility. NTAR Bizz is
            not accountable for these errors. Users must contact the party to
            whom the payment was sent to request a refund. NTAR Bizz will not
            reimburse or reverse a payment made by mistake.
          </ul>
        </>
      ),
    },
    Disclaimers: {
      icon: Fee,
      subtitle: "Disclaimers",
      content: (
        <>
          <h3>Online Transactions Related Risks</h3>
          <ul>
            <li>
              By using this site, users agree to bear all risks from online
              transactions.
            </li>
            <li>
              Services Provided “As Is”: Users are responsible for evaluating
              the accuracy, completeness, and usefulness of the services and
              information provided by NTAR Bizz. NTAR Bizz does not authorize
              any warranties on its behalf. All warranties, representations,
              conditions, and terms, whether express or implied, are excluded.
            </li>
          </ul>
          <p>
            By using the NTAR Bizz app, users agree to these terms and
            conditions, ensuring a secure and efficient experience with our
            Recharge and Bill Pay services.
          </p>
        </>
      ),
    },

    BBPOU: {
      icon: book,
      subtitle:
        "Bharat Bill Payment Operating Unit (BBPOU) Complaint Process and Other Terms",
      content: (
        <>
          <h3>Transaction Awaiting Confirmation</h3>
          <p>
            Generally, bill payments and recharges through NTAR Bizz are
            confirmed quickly. However, there may be instances where
            confirmation takes longer than usual. If your bill payment or
            recharge is pending, NTAR Bizz is waiting for confirmation from the
            service provider. This procedure may require up to 96 hours to
            complete. If your transaction is not confirmed within 24 hours, you
            are allowed to raise a ticket with us. NTAR Bizz customer service
            will follow up with the service provider to expedite the
            confirmation process. We are committed to resolving issues quickly
            to ensure the best service for our customers.
          </p>

          <h3>Completed Transactions</h3>
          <p>
            If your bill payment or recharge is successfully completed but the
            service is not updated or confirmed, please wait for 48 hours. If
            the issue persists after 48 hours, report the problem to us. Our
            officials will review your complaint and aim to resolve it within 48
            business hours, but not later than 30 business days. For more
            detailed information, please refer to our grievance policy.
          </p>

          <h3>Getting Your Money Back/Refund</h3>
          <p>
            Refunds will be processed to the original payment source if
            applicable.
          </p>
          <ul>
            <strong>Card Payments:</strong> Refunds will be processed within 7
            to 9 days.
            <br />
            <strong>Wallet and Gift Cards:</strong> Refunds will be processed
            within 24 hours.
          </ul>
        </>
      ),
    },
    "Terms&Conditions": {
      icon: Education,
      subtitle: "Additional Terms and Conditions",
      content: (
        <>
          <h3>Additional Terms and Conditions</h3>
          <p>
            The following additional terms and conditions are incorporated by
            reference to the General Terms and Conditions section:
          </p>
          <ul>
            <li>
              <strong>Indemnification:</strong> Users agree to indemnify and
              hold NTAR Bizz harmless from any claims arising from their use of
              the service.
            </li>
            <li>
              <strong>Privacy:</strong> Users' personal information will be
              handled according to our Privacy Policy.
            </li>
            <li>
              <strong>Responsibilities:</strong> Users are responsible for
              ensuring the accuracy of the information they provide and for any
              charges incurred.
            </li>
            <li>
              <strong>Registration:</strong> Users must complete the
              registration process and agree to the terms and conditions to use
              the service.
            </li>
            <li>
              <strong>Intellectual Property:</strong> All content and services
              provided by NTAR Bizz are protected by intellectual property laws.
            </li>
            <li>
              <strong>General Provisions:</strong> These terms and conditions
              constitute the entire agreement between the user and NTAR Bizz.
            </li>
            <li>
              <strong>Confidentiality:</strong> Users agree to keep any
              confidential information obtained through the service
              confidential.
            </li>
          </ul>
          <p>
            By using the NTAR Bizz app, users agree to these terms and
            conditions, ensuring a secure and efficient experience with our
            Recharge and Bill Pay services.
          </p>
        </>
      ),
    },
  };

  return (
    <>
      <div style={{ overflowX: "hidden", background: "white" }}>
        <div style={{ overflowX: "hidden" }} className="faqrecharge-container">
          <aside className="sidebar">
            <ul>
              {Object.keys(sections).map((section, index) => (
                <React.Fragment key={index}>
                  <li>
                    <li key={section}>
                      <a
                        href="#"
                        onClick={() => setSelectedSection(section)}
                        style={{
                          fontWeight:
                            selectedSection === section ? "bold" : "normal",
                        }}
                      >
                        <img
                          src={sections[section].icon}
                          alt={section}
                          className="sidebar-icon"
                        />
                        {section}
                      </a>
                    </li>
                  </li>
                  <hr />
                </React.Fragment>
              ))}
            </ul>
          </aside>

          {/* Content Area */}
          <section className="contentrecharge">
            <div className="disclaimer-section">
              <p style={{ margin: "0px" }}>
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="/Termsandconditions"
                >
                  <span style={{ fontFamily: "Youth_bold", fontSize: "13px" }}>
                    Terms&Conditions / Recharge Bill Payments{" "}
                  </span>
                </a>
                / {selectedSection}
              </p>
              <h1 className="termscond">
                {sections[selectedSection].subtitle}
              </h1>
              <br />

              <h3>{sections[selectedSection].title}</h3>
              <p>{sections[selectedSection].content}</p>
            </div>
          </section>
        </div>
        <Help
          buttonLabel="Chat Support"
          href="https://wa.me/9188545454"
          isExternalLink={true}
        />
        <br />
        <Footer />
      </div>
    </>
  );
};

export default Rechargebillfaq;
