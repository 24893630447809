import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./FAQ.css";
import Help from "../../Components/Help/Help.jsx";
import Footer from "../../Components/Footer.jsx";
import book from "../../assets/Image/book.png";
import goldsavings from "../../assets/Image/goldsav.png";
import Eligibility from "../../assets/Image/Eligibility.png";
import ntarGiforg from "../../assets/Image/orangecoinflip .webp";
import { GlobalContext } from "../../Components/GlobalContext/GlobalContext.jsx";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const helpCards = [
  {
    id: 1,
    img: book,
    title: "Getting Started",
    description:
      "Provides answers to frequently asked questions for new users.",
  },
  {
    id: 2,
    img: book,
    title: "Bill Payments",
    description: "Common questions regarding bill payment services",
  },
  {
    id: 3,
    img: goldsavings,
    title: "Pay Rent",
    description:
      "Addresses frequently asked questions about rent payment services.",
  },
  {
    id: 4,
    img: Eligibility,
    title: "Credit Card Bill Payment",
    description: "This section covers FAQs on credit card bill payments.",
  },
];

const FAQ = () => {
  const { activeHeader, setActiveHeader } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCards, setFilteredCards] = useState(helpCards);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  console.log("activeHeader====>,,activeHeader", activeHeader);
  const filteredhelpCards = helpCards.filter((topic) =>
    topic.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    setNoResultsFound(filteredhelpCards.length === 0);
  }, [filteredhelpCards]);

  const handleSearch = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = helpCards.filter((card) =>
      card.title.toLowerCase().includes(query)
    );
    setFilteredCards(filtered);
  };
  const location = useLocation();

  useEffect(() => {
    // Function to send page view information to Google Analytics
    const trackPageView = (url) => {
      window.gtag("config", "G-XNZ6JS7Y9J", {
        page_path: url,
      });
    };

    // Track page view when the location changes
    trackPageView(location.pathname);
  }, [location]);

  return (
    <div style={{ background: "white" }}>
      <Helmet>
        <title>FAQ - NTAR Bizz</title>
        <meta
          name="description"
          content="Find answers to frequently asked questions about NTAR Bizz. Learn more about our business payment solutions, security measures, and services."
        />
        <meta
          name="keywords"
          content="FAQ -NTAR Bizz, faq, faq questions, NTAR Bizz Facts"
        />
        {/* Canonical URL for this page */}
        <link rel="canonical" href="https://ntarbizz.com/FAQ/" />
      </Helmet>

      <section className="privacy-policy">
        <h1
          style={{
            color: "white",
            fontSize: "60px",
            fontFamily: "Youth",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          How can
          <br />
          we{" "}
          <img
            className="home__gif-help"
            src={ntarGiforg}
            alt="NTAR Animation"
          />
          Help
        </h1>
        <br />
        <br />
        <div className="support-center">
          <p style={{ fontSize: "18px" }}>Support Center</p>
          <p
            className="browse-through"
            style={{
              color: "white",
              fontSize: "14px",
              textAlign: "center",
              fontFamily: "BananaGroteskRegular" ,
            }}
          >
            Browse through our frequently asked questions, tutorials, and other
            self-help resources to
            <br />
            find the answers you need.
          </p>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Ex: Rent"
              value={searchQuery}
              onChange={handleSearch}
            />
            <button>Search</button>
          </div>

          <div className="popular-queries">
            <span className="popular">POPULAR :</span>
            <a href="/Helpfaq">What is Rent?</a>
            <a href="/Helpfaq">How can I pay my bills?</a>
          </div>
        </div>
      </section>

      <section className="help-center">
        <div className="small_cards">
          <h2>Browse our help centre</h2>
          <div className="help-cards">
            {filteredCards.length > 0 ? (
              // filteredCards.map((card) => (
              //   <a key={card.id} className="help-card" href="/HelpFaq">
              //     <img className="icon_size" src={card.img} alt="help-icon" />
              //     <h3 style={{ textDecoration: "none", color: "black" }}>
              //       {card.title}
              //     </h3>
              //     <p>{card.description}</p>
              //   </a>
              // ))
              filteredCards.map((card, index) => (
                <a
                  key={index}
                  className="help-card"
                  href="/HelpFaq"
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveHeader(card.title);
                    navigate("/HelpFaq");
                  }}
                >
                  <img className="icon_size" src={card.img} alt="help-icon" />
                  <h3 style={{ textDecoration: "none", color: "black" }}>
                    {card.title}
                  </h3>
                  <p>{card.description}</p>
                </a>
              ))
            ) : (
              <p style={{ textAlign: "left", fontSize: "18px", color: "red" }}>
                Sorry No results found
              </p>
            )}
          </div>
        </div>
      </section>
      <Help
        buttonLabel="Chat Support"
        href="https://wa.me/9188545454"
        isExternalLink={true}
      />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default FAQ;
