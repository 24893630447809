import React from "react";
import "./Choice.css";
import PayImg from "../../assets/Image/payimg.webp";
import PosImg from "../../assets/Image/pos.webp";
import Pos2Img from "../../assets/Image/pos2.webp";
import Pos3Img from "../../assets/Image/pos3.webp";
const Choice = () => {
  return (
    <div className="choice_container">
      <div className="choice_side_img">
        <img className="choice_img" src={PayImg} alt="payment image" />
        <div className="choice_full_side_text">
          <p className="choice_text_side">Text Content</p>
        </div>
      </div>
      <div className="line"></div>

      <div className="choice_three_images">
        <div className="choice_img_div">
          <img className="image_1" src={PosImg} alt="payment image" />
          <div className="choice_full_side_text">
            <p className="choice_text">POS</p>
            <p className="choice_sub_text">Learn more</p>
          </div>
        </div>

        <div className="choice_img_div">
          <img className="image_1" src={Pos2Img} alt="payment image" />
          <div className="choice_full_side_text">
            <p className="choice_text">Sound Box</p>
            <p className="choice_sub_text">Learn more</p>
          </div>
        </div>

        <div className="choice_img_div">
          <img className="image_1" src={Pos3Img} alt="payment image" />
          <div className="choice_full_side_text">
            <p className="choice_text">QR Code</p>
            <p className="choice_sub_text">Learn more</p>
          </div>
        </div>
      </div>


      <div className="lite_orange_box_choice">
                  <div className="inventory_list_choice">
                    <div className="list_item">
                      <p>POS</p>
                      <p className="arrow_icon">→</p>
                      <div className="hover_image invoice_image">
                        <img
                          className="normal_img_com"
                          src={PayImg}
                          alt="Invoice Management"
                        />
                        <div className="next_texts">
                          <p className="posdevice_2">POS Device</p>
                          <p className="Learn_more_2">Learn more</p>
                        </div>
                      </div>
                    </div>
                    <div className="list_item">
                      <p>Sound Box</p>
                      <p className="arrow_icon">→</p>
                      <div className="hover_image multi_invoice_image">
                        <img
                          className="normal_img_com"
                          src={PayImg}
                          alt="Invoice Management"
                        />
                        <div className="next_texts">
                          <p className="posdevice_2">POS Device</p>
                          <p className="Learn_more_2">Learn more</p>
                        </div>
                      </div>
                    </div>
                    <div className="list_item">
                      <p>QR Code</p>
                      <p className="arrow_icon">→</p>
                      <div className="hover_image expenses_image">
                        <img
                          className="normal_img_com"
                          src={PayImg}
                          alt="Invoice Management"
                        />
                        <div className="next_texts">
                          <p className="posdevice_2">POS Device</p>
                          <p className="Learn_more_2">Learn more</p>
                        </div>
                      </div>
                    </div>
                  
                  
                  </div>
                </div>

    </div>
  );
};

export default Choice;
