import React, { useState, useEffect } from "react";
import "./TermsCondfaq.css";
import Help from "../../Components/Help/Help.jsx";
import Footer from "../../Components/Footer.jsx";
import book from "../../assets/Image/book.png";
import Education from "../../assets/Image/education.png";
import goldsavings from "../../assets/Image/goldsav.png";
import Eligibility from "../../assets/Image/Eligibility.png";
import Accountreg from "../../assets/Image/Accountreg.png";
import Credentials from "../../assets/Image/credentialsconfi.png";
import ntarservices from "../../assets/Image/ntarservices.png";
import userobligations from "../../assets/Image/userobligations.png";
import Fee from "../../assets/Image/fee.png";
import Header from "../../Components/Header.jsx";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";

const TermsCondfaq = () => {
  const location = useLocation();

  useEffect(() => {
    const trackPageView = (url) => {
      window.gtag("config", "G-XNZ6JS7Y9J", {
        page_path: url,
      });
    };

    // Track page view when the location changes
    trackPageView(location.pathname);
  }, [location]);
  const [selectedSection, setSelectedSection] = useState("TermsConditions");
  const sections = {
    TermsConditions: {
      icon: book,
      title: "Purpose:",
      subtitle: "TermsConditions",
      content: (
        <>
          <strong>Electronic Record and Merchant Terms and Conditions:</strong>
          <p>
            This document constitutes an electronic record under the Information
            Technology Act, 2000, and relevant rules and amendments pertaining
            to electronic records. As per the Information Technology Act, 2000,
            this electronic record is generated by a computer system and does
            not require any physical or digital signatures.
          </p>
          <br />
          <strong>Merchant Terms and Conditions</strong>
          <p>
            These Merchant Terms and Conditions outline the terms applicable to
            different categories of merchants using NTAR Bizz services. The
            terms are categorized as follows:
          </p>
          <ul>
            <li>Merchant Terms and Conditions (Offline)</li>
            <li>Merchant Terms and Conditions Online (B2B)</li>
          </ul>
          <p>
            Merchants should refer to the specific category that applies to
            their business. If a merchant has previously entered into separate
            terms and conditions with NTAR Bizz, whether for online and/or
            offline services, or has executed an agreement with NTAR Bizz, those
            terms shall take precedence and supersede these terms and conditions
            in their entirety.
          </p>
        </>
      ),
    },

    "Legal Binding Agreement": {
      icon: goldsavings,
      subtitle: "Legal Binding Agreement",
      content: (
        <>
          <p>
            These terms and conditions constitute a legal contract ("Agreement")
            between you and NTAR BIZZ PRIVATE LIMITED, with its registered
            office at 5-5-165/2/TF, beside Vanasthali Hills, Vanasthalipuram,
            Ranga Reddy, Hyderabad, Telangana 500070, India. By accessing or
            using NTAR Bizz, you acknowledge that you have thoroughly reviewed
            and understood these terms and conditions. If you do not agree with
            these terms or do not wish to be bound by them, you should cease
            using or uninstall the NTAR Bizz app immediately.
          </p>
          <br />
          <p>
            NTAR BIZZ Private Limited reserves the right to amend these terms
            and conditions at any time by updating the version on the NTAR Bizz
            website and NTAR Bizz app. Any updates to the Terms of Service will
            take effect immediately upon posting on the official website and
            app. It is your responsibility to review these terms periodically
            for any changes. Your continued use of the NTAR Bizz app after any
            changes are posted constitutes your acceptance of the revised terms
            and conditions.
          </p>
          <br />
          <p>
            By agreeing to these terms, you are permitted to use the services
            provided by NTAR Bizz. Your use of the NTAR Bizz app signifies your
            agreement to all terms and conditions outlined herein. You also
            agree to adhere to NTAR Bizz and NTAR Bizz Entity Policies, as
            available on the NTAR Bizz website and app, which may be updated
            from time to time.
          </p>
        </>
      ),
    },
    Eligibility: {
      icon: Eligibility,
      subtitle: "Eligibility",
      content: (
        <>
          To use NTAR Bizz, you must meet the following eligibility criteria:
          <br />
          <br />
          <ul>
            <li>
              You must be at least 18 years old or the age of majority in your
              jurisdiction.
            </li>
            <li>
              You must possess the legal capacity to enter into and be bound by
              these terms and conditions.
            </li>
            <li>
              You must have a valid and active business registration or license,
              as applicable, in your jurisdiction.
            </li>
            <li>
              You must provide accurate and complete information as required
              during the registration or account setup process.
            </li>
          </ul>
          <br />
          <strong>Business Type:</strong>
          <br />
          NTAR Bizz services are available to both online (B2B PG) and offline
          merchants. Your business must comply with all relevant laws and
          regulations governing your industry and the type of transactions you
          conduct.
        </>
      ),
    },
    "Account Registration": {
      icon: Accountreg,
      subtitle: "Account Registration",
      content: (
        <>
          To use NTAR Bizz services, you must create an account by providing
          accurate and complete information as requested. You are responsible
          for maintaining the confidentiality of your account credentials and
          for all activities that occur under your account.
          <br />
          <br />
          NTAR Bizz Private Limited reserves the right to refuse service or
          terminate accounts at its discretion if it determines that you do not
          meet the eligibility criteria or have violated any terms of this
          Agreement.
          <br />
          <br />
          <strong>Compliance:</strong>
          <br />
          By using NTAR Bizz, you agree to comply with all applicable laws,
          regulations, and industry standards relevant to your business
          operations. You must ensure that all transactions conducted through
          NTAR Bizz are legal and not related to prohibited or restricted
          activities as defined by NTAR BIZZ PRIVATE LIMITED.
          <br />
          <br />
          <strong>Verification:</strong>
          <br />
          NTAR BIZZ PRIVATE LIMITED may, at its discretion, require additional
          documentation or information to verify your eligibility or business
          details. Failure to provide such documentation upon request may result
          in suspension or termination of your account.
        </>
      ),
    },

    Definitions: {
      icon: Education,
      title: "Definitions",
      subtitle: "Definitions",
      content: `Affiliate: Refers to any person or entity that has a direct or indirect connection with another person or entity through common ownership, control, or influence. "Control" in this context means the ability to guide the management and strategic decisions of an individual or entity, whether through ownership of voting rights, contractual arrangements, or other means. The terms "controlling" and "controlled" shall have similar interpretations.`,
    },

    "Refund Policy": {
      icon: Education,
      title: "Refund Policy",
      subtitle: "Refund Policy",
      content: `Refers to the reversal of an approved and settled transaction based on a User's complaint, deducted from the Merchant’s Settlement Amount in the Designated NTAR Account. This term also includes any associated charges, such as bank fees, penalties, and other costs imposed by banks, financial institutions, or regulatory bodies related to the transaction reversal.`,
    },

    "NTAR Bizz Pay": {
      icon: goldsavings,
      subtitle: "NTAR Bizz PAY:",
      content: (
        <>
          <ul className="ntar-bizz-pay">
            <li>Bill payments</li>
            <li>Single</li>
            <li>Multi/Bulk</li>
            <li>Rental & Credit Card</li>
            <li>Single</li>
            <li>Multi/Bulk</li>
          </ul>
        </>
      ),
    },

    "NTAR Bizz Choice": {
      icon: goldsavings,
      subtitle: "NTAR Bizz Choice:",
      content: (
        <>
          <ul className="ntar-bizz-choice">
            <li>Current Account Management</li>
            <li>Manage</li>
            <li>POS Devices, Soundbox, QR Code</li>
          </ul>
        </>
      ),
    },
    "NTAR Bizz Link": {
      icon: Credentials,
      subtitle: "NTAR Bizz Link",
      content: (
        <>
          <ul className="ntar-bizz-link">
            <li>Pay Contacts</li>
            <li>Vendor Payments</li>
            <li>Business Payments</li>
            <li>Customer</li>
            <li>Get Paid</li>
            <li>Payment Collection</li>
            <li>Settlements</li>
            <li>Reconciliation</li>
          </ul>
        </>
      ),
    },
    "NTAR Bizz Service Fee": {
      icon: ntarservices,
      subtitle: "NTAR Bizz Service Fee",
      content: (
        <>
          <p>
            a) All rates are exclusive of Goods and Services Tax (GST) and any
            other taxes that may apply or become applicable due to government
            notifications or regulations, unless otherwise specified.
          </p>
          <p>
            b) Applicable Tax Deducted at Source (TDS) will be deducted as
            required by law.
          </p>
          <p>
            c) The Merchant is responsible for bearing and paying all relevant
            taxes, including GST, in connection with User Payments made by Users
            under these Terms.
          </p>
          <p>
            d) Any changes to the NTAR Service Fee and/or Transactional SMS
            Service Fee will be communicated through the NTAR Merchant App, or
            via email/SMS, or both.
          </p>
          <p>
            e) The NTAR Service Fee is non-refundable in the event of
            transaction cancellations, Chargebacks, or refunds.
          </p>
          <br />
          <strong>“User”:</strong> An individual registered on the NTAR Bizz app
          or otherwise, using NTAR Bizz Services through various payment methods
          including debit card or credit card, and receiving the benefits of
          NTAR's Bizz payment technology.
        </>
      ),
    },
    "User Obligations": {
      icon: userobligations,
      subtitle: "User Obligations",
      content: (
        <>
          As a User of NTAR Bizz, you agree to the following obligations:
          <br />
          <br />
          <ul>
            <strong>Accurate Information:</strong> Provide accurate, complete,
            and up-to-date information when creating or maintaining your NTAR
            Bizz account. This includes, but is not limited to, business
            details, contact information, and payment information.
            <br />
            <br />
            <strong>Compliance with Laws:</strong> Adhere to all applicable
            laws, regulations, and guidelines in relation to the use of NTAR
            Bizz services. Ensure that all transactions conducted through the
            NTAR Bizz platform comply with legal requirements.
            <br />
            <br />
            <strong>Proper Use of Services:</strong> Use NTAR Bizz services
            solely for legitimate business purposes and in accordance with these
            Terms and Conditions. Avoid any fraudulent, illegal, or unauthorized
            activities.
            <br />
            <br />
            <strong>Security:</strong> Maintain the confidentiality and security
            of your account credentials, including your password and any other
            access information. Notify NTAR Bizz immediately if you suspect any
            unauthorized use of your account or any security breaches.
            <br />
            <br />
            <strong>Payment Responsibilities:</strong> Ensure timely payment of
            all fees and charges associated with NTAR Bizz services. This
            includes the NTAR Bizz Service Fee, Transactional SMS Service Fee,
            and any other applicable charges.
            <br />
            <br />
            <strong>Dispute Resolution:</strong> Address any disputes or issues
            with transactions promptly and in accordance with NTAR Bizz dispute
            resolution procedures. Cooperate with NTAR Bizz in resolving any
            such disputes or issues.
            <br /> <br />
            <strong>Notification of Changes:</strong> Inform NTAR Bizz of any
            changes to your business details or other relevant information that
            may impact your use of NTAR Bizz services.
            <br /> <br />
            <strong>Respect for Intellectual Property:</strong> Respect NTAR
            Bizz intellectual property rights, including trademarks, copyrights,
            and patents. Do not use NTAR Bizz intellectual property without
            proper authorization.
            <br /> <br />
            <strong>Adherence to Policies:</strong> Follow all NTAR Bizz
            policies and guidelines related to the use of NTAR Bizz services,
            including any updates or amendments to these policies.
            <br /> <br />
            <strong>Liability for Transactions:</strong> Assume full
            responsibility for all transactions conducted through your NTAR Bizz
            account. This includes ensuring that all transactions are accurate
            and authorized.
          </ul>
        </>
      ),
    },
    FraudulentBehavior: {
      icon: Fee,
      subtitle: "Fraudulent Behavior:",
      content: (
        <>
          NTAR Bizz Private Limited takes a firm stance against fraudulent
          behavior and requires all users to engage with the NTAR Bizz App in a
          lawful and honest manner. The company utilizes industry-standard
          security measures to detect and prevent fraud, including advanced
          technologies for monitoring transactions and verifying user
          information.
          <br />
          <br />
          Users are responsible for ensuring that their activities on the NTAR
          Bizz App are free from deceitful practices and must safeguard their
          account credentials to prevent unauthorized access. In the event of
          suspected fraudulent activity, users must promptly report any issues
          to NTAR Bizz’s support team to mitigate potential damage.
          <br />
          <br />
          NTAR Bizz Private Limited reserves the right to take decisive action
          against users engaged in fraudulent behavior, including account
          suspension, termination, legal action, and reporting to relevant
          authorities. Users are liable for any losses or damages resulting from
          their involvement in fraud and agree to cooperate with investigations
          conducted by NTAR Bizz and law enforcement agencies.
          <br />
          <br />
          NTAR Bizz may periodically review and update its security practices to
          address emerging threats and enhance fraud protection.
        </>
      ),
    },

    CommunicationPolicy: {
      icon: book,
      subtitle: "Communication Policy",
      content: (
        <>
          NTAR Bizz Private Limited is committed to maintaining clear,
          effective, and timely communication with its users. Our communication
          policy ensures that all interactions, whether through the NTAR Bizz
          App, email, SMS, or other channels, are conducted professionally and
          in accordance with applicable regulations. We provide regular updates
          on service changes, important notices, and relevant information to
          keep users informed.
          <br />
          <br />
          Users are encouraged to reach out with any queries or concerns via our
          designated support channels, and we strive to respond promptly and
          thoroughly. To ensure compliance and address any issues, NTAR Bizz
          Private Limited may also send periodic communications related to
          account activities, security alerts, and updates on our policies.
          <br />
          <br />
          Users are responsible for keeping their contact information up to date
          to receive these important communications. We value transparent and
          responsive dialogue as part of our commitment to providing exceptional
          service and support.
        </>
      ),
    },

    Disclaimers: {
      icon: Fee,
      subtitle: "Disclaimers",
      content: (
        <>
          <ul>
            <strong>No Warranty:</strong> NTAR Bizz Private Limited provides the
            NTAR Bizz App and services on an "as-is" basis without warranties of
            any kind, either express or implied. We do not guarantee the
            accuracy, reliability, or availability of the app or its services.
            <br />
            <br />
            <strong>Limitation of Liability:</strong> NTAR Bizz is not liable
            for any direct, indirect, incidental, or consequential damages
            arising from the use or inability to use the app, including but not
            limited to loss of data, profits, or business opportunities.
            <br />
            <br />
            <strong>Changes to Services:</strong> NTAR Bizz reserves the right
            to modify or discontinue any aspect of the app or services at any
            time without prior notice.
            <br />
            <br />
            <strong>User Responsibility:</strong> Users are responsible for
            their own actions and transactions conducted through the app. Ensure
            compliance with all applicable laws and regulations.
          </ul>
        </>
      ),
    },
  };

  return (
    <>
      <div style={{ overflowX: "hidden", background: "white" }}>
        <Helmet>
          <link
            rel="canonical"
            href="https://ntarbizz.com/Termsandconditions/"
          />
        </Helmet>
        {/* <Headernew /> */}
        <div style={{ overflowX: "hidden" }} className="faqterms-container">
          {/* Sidebar */}
          <aside className="sidebar">
            <ul>
              {Object.keys(sections).map((section, index) => (
                <React.Fragment key={index}>
                  <li>
                    <li key={section}>
                      <a
                        href="#"
                        onClick={() => setSelectedSection(section)}
                        style={{
                          fontWeight:
                            selectedSection === section ? "bold" : "normal",
                        }}
                      >
                        <img
                          src={sections[section].icon}
                          alt={section}
                          className="sidebar-icon"
                        />
                        {section}
                      </a>
                    </li>
                  </li>
                  <hr />
                </React.Fragment>
              ))}
            </ul>
          </aside>

          {/* Content Area */}
          <section
            className={
              selectedSection === "NTAR Bizz Pay" ||
              selectedSection === "NTAR Bizz Choice" ||
              selectedSection === "NTAR Bizz Link"
                ? "contentfaq no-margin-left"
                : "contentfaq"
            }
          >
            <div className="disclaimer-section">
              <p style={{ margin: "0px" }}>
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="/Termsandconditions"
                >
                  <span style={{ fontFamily: "Youth_bold", fontSize: "13px" }}>
                    Terms&Conditions / General T&C{" "}
                  </span>
                </a>
                / {selectedSection}
              </p>
              <h1 className="termscond">
                {sections[selectedSection].subtitle}
              </h1>
              <br />

              <h3>{sections[selectedSection].title}</h3>
              <p>{sections[selectedSection].content}</p>
            </div>
          </section>
        </div>
        <Help
          buttonLabel="Chat Support"
          href="https://wa.me/9188545454"
          isExternalLink={true}
        />
        <br />
        <Footer />
      </div>
    </>
  );
};

export default TermsCondfaq;
