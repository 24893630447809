import React, { createContext, useState } from "react";

export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [activeHeader, setActiveHeader] = useState("");
  console.log("activeHeader in global===>", activeHeader);
  return (
    <GlobalContext.Provider value={{ activeHeader, setActiveHeader }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
