import React, { useState,useEffect  } from "react";
import "./Termsandconditions.css";
import Help from "../../Components/Help/Help.jsx";
import Footer from "../../Components/Footer.jsx";
import book from "../../assets/Image/book.png";
import Education from "../../assets/Image/education.png";
import goldsavings from "../../assets/Image/goldsav.png";
import Eligibility from "../../assets/Image/Eligibility.png";
import Accountreg from "../../assets/Image/Accountreg.png";
import Credentials from "../../assets/Image/credentialsconfi.png";
import ntarservices from "../../assets/Image/ntarservices.png";
import userobligations from "../../assets/Image/userobligations.png";
import Fee from "../../assets/Image/fee.png";
import { Helmet } from 'react-helmet';
import { useLocation } from "react-router-dom";

const helpCardsData = [
  {
    id: 1,
    link: "/TermsCondfaq",
    imgSrc: book,
    title: "General T&C",
    description: "Terms govern your use of our services",
  },
  {
    id: 2,
    link: "/RentalFaq",
    imgSrc: Education,
    title: "Rental",
    description: "This section covers rental terms and agreements.",
  },
  {
    id: 3,
    link: "/Rechargebillfaq",
    imgSrc: goldsavings,
    title: "Recharge & Bill Payments",
    description: "Outlines the terms for recharge and bill payment services.",
  },
  {
    id: 4,
    link: "/Creditcardfaq",
    imgSrc: Eligibility,
    title: "Credit Card",
    description: "Terms related to credit card transactions",
  },
  {
    id: 5,
    link: "/Expensemanfaq",
    imgSrc: Accountreg,
    title: "Expense Management",
    description: "Covers the terms for managing expenses effectively.",
  },
  {
    id: 6,
    link: "#",
    imgSrc: userobligations,
    title: "Loans",
    description: "Section outlines the terms related to loan services.",
    comingSoon: true,
  },
  {
    id: 7,
    link: "#",
    imgSrc: Fee,
    title: "POS",
    description: "Terms for Point of Sale (POS) services",
    comingSoon: true,
  },
];

const Termsandconditions = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredCards = helpCardsData.filter((card) =>
    card.title.toLowerCase().includes(searchTerm)
  );
  
  const location = useLocation();

  useEffect(() => {
    // Function to send page view information to Google Analytics
    const trackPageView = (url) => {
      window.gtag("config", "G-XNZ6JS7Y9J", {
        page_path: url,
      });
    };

    // Track page view when the location changes
    trackPageView(location.pathname);
  }, [location]);

  return (
    <div style={{ background: "white" }}>
        <Helmet>
        <title>Terms & Conditions | NTAR Bizz - Secure Business Payment Solutions</title>
        <meta
          name="description"
          content="Review the Terms & Conditions for NTAR Bizz. Understand the guidelines for using our secure payment gateway solutions, merchant services, and financial tools."
        />
        <meta
          name="keywords"
          content="NTAR bizz Terms & Conditions, Service Terms, Terms of Service, ntar bizz Terms"
        />
        {/* Canonical URL for this page */}
        <link rel="canonical" href="https://ntarbizz.com/Termsandconditions/" />
      </Helmet>
      <section className="privacy-policy">
        <h1
          style={{
            color: "white",
            fontSize: "70px",
            fontFamily: "Youth",
            fontWeight: "700",
            textAlign: "center",
          }}
        >
          Terms & Conditions
        </h1>
        <br />
        <br />
        <br />
        <div className="support-center">
          <p style={{ fontSize: "18px" }}>Support Center</p>
          <p
            className="browse-through"
            style={{ color: "white", fontSize: "14px", textAlign: "center" }}
          >
            Browse through our frequently asked questions, tutorials, and other
            self-help resources to
            <br /> find the answers you need.
          </p>
          <div className="search-bar">
            <input
              type="text"
              placeholder="Ex: Rent"
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <button>Search</button>
          </div>
        </div>
      </section>

      <section className="help-center">
        <div className="small_cards">
          <h2>Browse our help centre</h2>
          <div className="help-cards">
            {filteredCards.length > 0 ? (
              filteredCards.map((card) => (
                <a key={card.id} className="help-card" href={card.link}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <img className="icon_size" src={card.imgSrc} alt="icon" />
                    {card.comingSoon && (
                      <div className="coming_soon_section">
                        <button className="coming_soon_Helpmenu">
                          Coming soon
                        </button>
                      </div>
                    )}
                  </div>
                  <h3 className="box">{card.title}</h3>
                  <p>{card.description}</p>
                </a>
              ))
            ) : (
              <p style={{ textAlign: "left", fontSize: "18px", color: "red" }}>
                Sorry No results found
              </p>
            )}
          </div>
        </div>
      </section>

      <Help
        buttonLabel="Chat Support"
        href="https://wa.me/9188545454"
        isExternalLink={true}
      />
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default Termsandconditions;
