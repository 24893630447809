import React, { useState,useEffect } from "react";
import "./Expensemanfaq.css";
import Help from "../../Components/Help/Help.jsx";
import Footer from "../../Components/Footer.jsx";
import book from "../../assets/Image/book.png";
import Education from "../../assets/Image/education.png";
import goldsavings from "../../assets/Image/goldsav.png";
import Eligibility from "../../assets/Image/Eligibility.png";
import Accountreg from "../../assets/Image/Accountreg.png";
import Credentials from "../../assets/Image/credentialsconfi.png";
import ntarservices from "../../assets/Image/ntarservices.png";
import userobligations from "../../assets/Image/userobligations.png";
import Fee from "../../assets/Image/fee.png";
import { useLocation } from "react-router-dom";

const Expensemanfaq = () => {
  const [selectedSection, setSelectedSection] = useState("Introduction");
  const location = useLocation();

  useEffect(() => {
    // Function to send page view information to Google Analytics
    const trackPageView = (url) => {
      window.gtag("config", "G-XNZ6JS7Y9J", {
        page_path: url,
      });
    };

    // Track page view when the location changes
    trackPageView(location.pathname);
  }, [location]);
  
  const sections = {
    Introduction: {
      icon: book,
      subtitle: "Introduction",
      content: (
        <>
          <h3>Introduction</h3>
          <p>
            NTAR Bizz, a financial app provided by NTAR BIZZ PRIVATE LIMITED
            ("NTAR Bizz," "we," "our," or "us"). By accessing or using our
            Expense Management services ("Services"), you must agree to these
            Terms and Conditions ("Terms").
          </p>
          <br />
          <p>
            NTAR Bizz securely connects to your bank accounts and provides a
            comprehensive view of your finances. This enables you to make
            informed decisions and improve your financial well-being.
          </p>
        </>
      ),
    },
    " Service Terms": {
      icon: goldsavings,
      subtitle: "Service Terms",
      content: (
        <>
          <h3>Expense Tracking with NTAR Bizz</h3>
          <p>
            NTAR Bizz simplifies expense tracking by automatically retrieving
            transaction details from your bank accounts. This feature allows the
            app to categorize your expenses, offering a comprehensive view of
            your spending patterns and financial habits. While NTAR Bizz
            endeavors to present accurate financial information, it's essential
            to recognize that the app relies on data directly sourced from your
            banks. Therefore, the completeness and accuracy of this data may
            vary and cannot be guaranteed consistently.
          </p>
          <br />
          <p>
            To ensure secure access to your financial information, NTAR Bizz
            implements a stringent security measure: the use of One-Time
            Passwords (OTPs). These OTPs are necessary to securely link and
            retrieve data from your bank accounts, safeguarding your privacy and
            financial details throughout the process.
          </p>
          <br />
          <p>
            By leveraging NTAR Bizz's automated expense tracking capabilities
            and its commitment to security through OTP verification, users can
            effectively manage their finances with confidence and peace of mind.
            This combination of convenience and security underscores NTAR Bizz's
            dedication to providing a reliable and user-friendly platform for
            financial management.
          </p>
        </>
      ),
    },

    "User Responsibilities": {
      icon: Eligibility,
      // title: "Eligibility",
      subtitle: "User Responsibilities",
      content: (
        <>
          <h3>Account Security and Information Accuracy</h3>
          <p>
            You are responsible for maintaining the confidentiality of your NTAR
            Bizz account and OTPs used for bank connections. Any unauthorized
            use of your account or OTPs must be reported to us immediately.
          </p>
          <p>
            You agree to provide accurate and current information when
            registering for and using NTAR Bizz. Any discrepancies in
            information provided may affect the functionality of the app.
          </p>
        </>
      ),
    },

    "Fraudulent Activities": {
      icon: Accountreg,
      subtitle: "Fraudulent Activities",
      content: (
        <>
          <h3>User Responsibilities and Prohibitions</h3>
          <ul>
            <li>
              You agree not to engage in any unauthorized access to NTAR Bizz
              accounts or systems.
            </li>
            <li>
              Prohibiting any attempts to manipulate data within the NTAR Bizz
              app or its associated systems.
            </li>
            <li>
              Users are not allowed to attempt to breach NTAR Bizz security
              protocols or infrastructure.
            </li>
            <li>
              Any fraudulent use of NTAR Bizz services, including exploiting
              vulnerabilities or bugs, is prohibited.
            </li>
            <li>
              Users must not engage in phishing or attempts to obtain sensitive
              information fraudulently.
            </li>
            <li>
              NTAR Bizz accounts should not be used for money laundering or
              illegal financial activities.
            </li>
            <li>
              Users are responsible for safeguarding their credentials and
              preventing unauthorized use.
            </li>
            <li>
              NTAR Bizz prohibits any use of automated scripts to manipulate or
              access the service.
            </li>
            <li>
              Users must report any suspected security vulnerabilities or
              incidents promptly.
            </li>
            <li>
              NTAR Bizz reserves the right to suspend or terminate accounts
              involved in fraudulent activities.
            </li>
          </ul>

          <h3>Reservation of Rights</h3>
          <p>
            NTAR Bizz reserves the right to modify, suspend, or discontinue any
            part of its expense management services at any time without prior
            notice. We won't be responsible to users or any other party for any
            such modification, suspension, or discontinuance.
          </p>

          <h3>Legal Compliance</h3>
          <p>
            Users agree to use NTAR Bizz in compliance with all applicable laws
            and regulations. NTAR Bizz shall not be liable for any illegal or
            unauthorized use of its expense management services by users.
          </p>
        </>
      ),
    },

    " Contact Us": {
      icon: ntarservices,
      subtitle: "Contact Us",
      content: (
        <>
          <p>
            If you have any questions or concerns about these terms and
            conditions, please contact us at{" "}
            <a href="mailto:hello@ntarbizz.com">hello@ntarbizz.com</a>.
          </p>
          <br></br>
          <p>
            By using NTAR Bizz, you acknowledge that you have read, understood,
            and agree to be bound by these terms and conditions. These terms
            constitute a legally binding agreement between you and NTAR BIZZ
            PRIVATE LIMITED.
          </p>
          <br></br>
          <p>
            By using NTAR Bizz, you agree to these Terms and Conditions. Thank
            you for choosing NTAR Bizz to manage your finances responsibly and
            effectively.
          </p>
        </>
      ),
    },
    "Third Party ": {
      icon: userobligations,
      // title: "User Obligations",
      subtitle: "Third Party ",
      content: `NTAR BIZZ PRIVATE LIMITED ("NTAR Bizz") collaborates with RBI-licensed account aggregators to offer Third Party Services, enabling you to access your financial information conveniently. By using NTAR Bizz services, you agree to adhere to the following terms: We utilize third-party products and services within the NTAR Bizz App to enhance functionality and may direct you to external applications and websites ("Third Party Services"). Your utilization of these services may entail acceptance of additional terms and compliance with specific requirements set by the respective third parties. NTAR Bizz ensures that all interactions with {keyword} are secure.`,
    },
    "Limitations of Liability": {
      icon: Fee,
      // title: "Fees",
      subtitle: "Limitations of Liability",
      content: (
        <>
          <h3>Accuracy of Information</h3>
          <p>
            NTAR Bizz strives to provide accurate expense management services.
            However, we do not guarantee the accuracy, completeness, or
            reliability of the financial information provided through NTAR Bizz.
            Users are responsible for verifying the accuracy of their financial
            data.
          </p>
          <h3>Consequential Damages</h3>
          <p>
            NTAR Bizz shall not be liable for any indirect, consequential,
            punitive, or incidental damages arising out of or related to the use
            of NTAR Bizz expense management services, including but not limited
            to loss of profits, loss of data, or business interruption.
          </p>
          <h3>Limitation of Liability</h3>
          <p>
            In no event shall NTAR Bizz or its affiliates, directors, officers,
            employees, agents, or suppliers be liable for damages exceeding the
            amount paid by the user for the NTAR Bizz services during the twelve
            (12) months preceding the event giving rise to the liability.
          </p>
          <h3>Force Majeure</h3>
          <p>
            NTAR Bizz shall not be liable for any failure or delay in performing
            its obligations under these terms and conditions if such failure or
            delay is due to events outside of its reasonable control, such as
            but not restricted to actions of God, natural disasters, war,
            terrorism, or governmental actions.
          </p>
          <h3>Disclaimer of Warranties</h3>
          <p>
            NTAR Bizz provides its expense management services on an "as is" and
            "as available" basis. We disclaim all warranties, express or
            implied, including but not limited to the implied warranties of
            merchantability, fitness for a particular purpose, and
            non-infringement.
          </p>
        </>
      ),
    },
  };

  return (
    <>
      <div style={{ overflowX: "hidden", background: "white" }}>
        <div style={{ overflowX: "hidden" }} className="faqexpense-container">
          <aside className="sidebar">
            <ul>
              {Object.keys(sections).map((section, index) => (
                <React.Fragment key={index}>
                  <li>
                    <li key={section}>
                      <a
                        href="#"
                        onClick={() => setSelectedSection(section)}
                        style={{
                          fontWeight:
                            selectedSection === section ? "bold" : "normal",
                        }}
                      >
                        <img
                          src={sections[section].icon}
                          alt={section}
                          className="sidebar-icon"
                        />
                        {section}
                      </a>
                    </li>
                  </li>
                  <hr />
                </React.Fragment>
              ))}
            </ul>
          </aside>

          <section className="contentexpense">
            <div className="disclaimer-section">
              <p style={{ margin: "0px" }}>
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="/Termsandconditions"
                >
                  <span style={{ fontFamily: "Youth_bold", fontSize: "13px" }}>
                    Terms&Conditions / Expense Management{" "}
                  </span>
                </a>
                / {selectedSection}
              </p>
              <h1 className="termscond">
                {sections[selectedSection].subtitle}
              </h1>
              <br />

              <h3>{sections[selectedSection].title}</h3>
              <p>{sections[selectedSection].content}</p>
            </div>
          </section>
        </div>
        <Help
          buttonLabel="Chat Support"
          href="https://wa.me/9188545454"
          isExternalLink={true}
        />
        <br />
        <br/>
        <Footer />
      </div>
    </>
  );
};

export default Expensemanfaq;
